/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #F4793B;
  --secondary: #673B15;
  --success: #00A13A;
  --info: #1DA1F2;
  --warning: #FFD600;
  --danger: #E30613;
  --light: #808A95;
  --dark: #2B3C4E;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1280px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2B3C4E;
  text-align: left;
  background-color: #FFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2B3C4E;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #4f6f90;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #808A95;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 700;
  line-height: 1.2;
  color: #2B3C4E; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFF;
  border: 1px solid #DDDDDD;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFF;
  background-color: #212529; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1200px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1420px; } }

.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2B3C4E; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #DDDDDD; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #DDDDDD; }
  .table tbody + tbody {
    border-top: 2px solid #DDDDDD; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #DDDDDD; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #DDDDDD; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #2B3C4E;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fcd9c8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f9b999; }

.table-hover .table-primary:hover {
  background-color: #fbc8b0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fbc8b0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4c8bd; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b09985; }

.table-hover .table-secondary:hover {
  background-color: #cabbae; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cabbae; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e5c8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ace99; }

.table-hover .table-success:hover {
  background-color: #a5deba; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a5deba; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c0e5fb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #89cef8; }

.table-hover .table-info:hover {
  background-color: #a8dbf9; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a8dbf9; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff4b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffea7a; }

.table-hover .table-warning:hover {
  background-color: #fff09f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff09f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7b9bd; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f07e84; }

.table-hover .table-danger:hover {
  background-color: #f4a2a7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f4a2a7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #dbdee1; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #bdc2c8; }

.table-hover .table-light:hover {
  background-color: #cdd1d5; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #cdd1d5; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c4c8cd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #919aa3; }

.table-hover .table-dark:hover {
  background-color: #b6bbc1; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b6bbc1; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFF;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #DDDDDD; }

.table-dark {
  color: #FFF;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1279.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.39em + 1.3124rem + 2px);
  padding: 0.6562rem 1.5rem;
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.39;
  color: #2B3C4E;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #DDDDDD;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #2B3C4E;
    background-color: #FFF;
    border-color: #fbccb4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #2B3C4E;
  background-color: #FFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.6562rem + 1px);
  padding-bottom: calc(0.6562rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.39; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.99995rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.74996rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.6562rem;
  padding-bottom: 0.6562rem;
  margin-bottom: 0;
  line-height: 1.39;
  color: #2B3C4E;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.74996rem;
  line-height: 1.5; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.99995rem;
  line-height: 1.5; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #808A95; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00A13A; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.74996rem;
  line-height: 1.5;
  color: #FFF;
  background-color: rgba(0, 161, 58, 0.9); }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00A13A;
  padding-right: calc(1.39em + 1.3124rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300A13A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.3475em + 0.3281rem);
  background-size: calc(0.695em + 0.6562rem) calc(0.695em + 0.6562rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00A13A;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 58, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.39em + 1.3124rem);
  background-position: top calc(0.3475em + 0.3281rem) right calc(0.3475em + 0.3281rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00A13A;
  padding-right: calc((1em + 1.3124rem) * 3 / 4 + 2.5rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.5rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300A13A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFF no-repeat center right 2.5rem/calc(0.695em + 0.6562rem) calc(0.695em + 0.6562rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #00A13A;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 58, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00A13A; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00A13A; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #00A13A; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00d44c;
  background-color: #00d44c; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 58, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00A13A; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00A13A; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00A13A;
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 58, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E30613; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.74996rem;
  line-height: 1.5;
  color: #FFF;
  background-color: rgba(227, 6, 19, 0.9); }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E30613;
  padding-right: calc(1.39em + 1.3124rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E30613' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E30613' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.3475em + 0.3281rem);
  background-size: calc(0.695em + 0.6562rem) calc(0.695em + 0.6562rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #E30613;
    box-shadow: 0 0 0 0.2rem rgba(227, 6, 19, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.39em + 1.3124rem);
  background-position: top calc(0.3475em + 0.3281rem) right calc(0.3475em + 0.3281rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E30613;
  padding-right: calc((1em + 1.3124rem) * 3 / 4 + 2.5rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.5rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E30613' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E30613' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #FFF no-repeat center right 2.5rem/calc(0.695em + 0.6562rem) calc(0.695em + 0.6562rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #E30613;
    box-shadow: 0 0 0 0.2rem rgba(227, 6, 19, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E30613; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E30613; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #E30613; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f9232f;
  background-color: #f9232f; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 6, 19, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E30613; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E30613; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E30613;
  box-shadow: 0 0 0 0.2rem rgba(227, 6, 19, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .homepage-row .custom-text a {
  display: inline-block;
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 700;
  color: #2B3C4E;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 1.75rem;
  font-size: 0.75rem;
  line-height: 1.333;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .homepage-row .custom-text a {
      transition: none; } }
  .btn:hover, .homepage-row .custom-text a:hover {
    color: #2B3C4E;
    text-decoration: none; }
  .btn:focus, .homepage-row .custom-text a:focus, .btn.focus, .homepage-row .custom-text a.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }
  .btn.disabled, .homepage-row .custom-text a.disabled, .btn:disabled, .homepage-row .custom-text a:disabled {
    opacity: 0.65; }

a.btn.disabled, .homepage-row .custom-text a.disabled,
fieldset:disabled a.btn,
fieldset:disabled .homepage-row .custom-text a,
.homepage-row .custom-text fieldset:disabled a {
  pointer-events: none; }

.btn-primary, .homepage-row .custom-text a {
  color: #FFF;
  background-color: #F4793B;
  border-color: #F4793B; }
  .btn-primary:hover, .homepage-row .custom-text a:hover {
    color: #FFF;
    background-color: #f26017;
    border-color: #ef590d; }
  .btn-primary:focus, .homepage-row .custom-text a:focus, .btn-primary.focus, .homepage-row .custom-text a.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 141, 88, 0.5); }
  .btn-primary.disabled, .homepage-row .custom-text a.disabled, .btn-primary:disabled, .homepage-row .custom-text a:disabled {
    color: #FFF;
    background-color: #F4793B;
    border-color: #F4793B; }
  .btn-primary:not(:disabled):not(.disabled):active, .homepage-row .custom-text a:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .homepage-row .custom-text a:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle,
  .homepage-row .custom-text .show > a.dropdown-toggle {
    color: #FFF;
    background-color: #ef590d;
    border-color: #e3540d; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .homepage-row .custom-text a:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .homepage-row .custom-text a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus,
    .homepage-row .custom-text .show > a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 141, 88, 0.5); }

.btn-secondary {
  color: #FFF;
  background-color: #673B15;
  border-color: #673B15; }
  .btn-secondary:hover {
    color: #FFF;
    background-color: #47290f;
    border-color: #3d230c; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(126, 88, 56, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFF;
    background-color: #673B15;
    border-color: #673B15; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFF;
    background-color: #3d230c;
    border-color: #321d0a; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(126, 88, 56, 0.5); }

.btn-success {
  color: #FFF;
  background-color: #00A13A;
  border-color: #00A13A; }
  .btn-success:hover {
    color: #FFF;
    background-color: #007b2c;
    border-color: #006e28; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 175, 88, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFF;
    background-color: #00A13A;
    border-color: #00A13A; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFF;
    background-color: #006e28;
    border-color: #006123; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 175, 88, 0.5); }

.btn-info {
  color: #FFF;
  background-color: #1DA1F2;
  border-color: #1DA1F2; }
  .btn-info:hover {
    color: #FFF;
    background-color: #0d8ddc;
    border-color: #0c85d0; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFF;
    background-color: #1DA1F2;
    border-color: #1DA1F2; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFF;
    background-color: #0c85d0;
    border-color: #0b7ec4; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #FFD600;
  border-color: #FFD600; }
  .btn-warning:hover {
    color: #212529;
    background-color: #d9b600;
    border-color: #ccab00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 187, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #FFD600;
    border-color: #FFD600; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ccab00;
    border-color: #bfa100; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 187, 6, 0.5); }

.btn-danger {
  color: #FFF;
  background-color: #E30613;
  border-color: #E30613; }
  .btn-danger:hover {
    color: #FFF;
    background-color: #be0510;
    border-color: #b1050f; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 43, 54, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFF;
    background-color: #E30613;
    border-color: #E30613; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFF;
    background-color: #b1050f;
    border-color: #a5040e; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(231, 43, 54, 0.5); }

.btn-light {
  color: #FFF;
  background-color: #808A95;
  border-color: #808A95; }
  .btn-light:hover {
    color: #FFF;
    background-color: #6d7782;
    border-color: #67717b; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(147, 156, 165, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #FFF;
    background-color: #808A95;
    border-color: #808A95; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #FFF;
    background-color: #67717b;
    border-color: #616a74; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(147, 156, 165, 0.5); }

.btn-dark {
  color: #FFF;
  background-color: #2B3C4E;
  border-color: #2B3C4E; }
  .btn-dark:hover {
    color: #FFF;
    background-color: #1d2935;
    border-color: #19232d; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 89, 105, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFF;
    background-color: #2B3C4E;
    border-color: #2B3C4E; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #FFF;
    background-color: #19232d;
    border-color: #141c25; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 89, 105, 0.5); }

.btn-outline-primary {
  color: #F4793B;
  border-color: #F4793B; }
  .btn-outline-primary:hover {
    color: #FFF;
    background-color: #F4793B;
    border-color: #F4793B; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #F4793B;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFF;
    background-color: #F4793B;
    border-color: #F4793B; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.5); }

.btn-outline-secondary {
  color: #673B15;
  border-color: #673B15; }
  .btn-outline-secondary:hover {
    color: #FFF;
    background-color: #673B15;
    border-color: #673B15; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 59, 21, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #673B15;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFF;
    background-color: #673B15;
    border-color: #673B15; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(103, 59, 21, 0.5); }

.btn-outline-success {
  color: #00A13A;
  border-color: #00A13A; }
  .btn-outline-success:hover {
    color: #FFF;
    background-color: #00A13A;
    border-color: #00A13A; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 58, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00A13A;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFF;
    background-color: #00A13A;
    border-color: #00A13A; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 161, 58, 0.5); }

.btn-outline-info {
  color: #1DA1F2;
  border-color: #1DA1F2; }
  .btn-outline-info:hover {
    color: #FFF;
    background-color: #1DA1F2;
    border-color: #1DA1F2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #1DA1F2;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFF;
    background-color: #1DA1F2;
    border-color: #1DA1F2; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5); }

.btn-outline-warning {
  color: #FFD600;
  border-color: #FFD600; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #FFD600;
    border-color: #FFD600; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 214, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFD600;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #FFD600;
    border-color: #FFD600; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 214, 0, 0.5); }

.btn-outline-danger {
  color: #E30613;
  border-color: #E30613; }
  .btn-outline-danger:hover {
    color: #FFF;
    background-color: #E30613;
    border-color: #E30613; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 6, 19, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #E30613;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFF;
    background-color: #E30613;
    border-color: #E30613; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(227, 6, 19, 0.5); }

.btn-outline-light {
  color: #808A95;
  border-color: #808A95; }
  .btn-outline-light:hover {
    color: #FFF;
    background-color: #808A95;
    border-color: #808A95; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 138, 149, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #808A95;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #FFF;
    background-color: #808A95;
    border-color: #808A95; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 138, 149, 0.5); }

.btn-outline-dark {
  color: #2B3C4E;
  border-color: #2B3C4E; }
  .btn-outline-dark:hover {
    color: #FFF;
    background-color: #2B3C4E;
    border-color: #2B3C4E; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 60, 78, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #2B3C4E;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #FFF;
    background-color: #2B3C4E;
    border-color: #2B3C4E; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(43, 60, 78, 0.5); }

.btn-link {
  font-weight: 400;
  color: #2B3C4E;
  text-decoration: none; }
  .btn-link:hover {
    color: #4f6f90;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .homepage-row .custom-text .btn-group-lg > a {
  padding: 0.5rem 1rem;
  font-size: 0.99995rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn, .homepage-row .custom-text a {
  padding: 0.6875rem 1rem;
  font-size: 0.75rem;
  line-height: 1.833;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #2B3C4E;
  text-align: left;
  list-style: none;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1280px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #F6F6F6; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFF;
    text-decoration: none;
    background-color: #F4793B; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.74996rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .homepage-row .custom-text .btn-group > a,
  .btn-group-vertical > .btn,
  .homepage-row .custom-text .btn-group-vertical > a {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .homepage-row .custom-text .btn-group > a:hover,
    .btn-group-vertical > .btn:hover,
    .homepage-row .custom-text .btn-group-vertical > a:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .homepage-row .custom-text .btn-group > a:focus, .btn-group > .btn:active, .homepage-row .custom-text .btn-group > a:active, .btn-group > .btn.active, .homepage-row .custom-text .btn-group > a.active,
    .btn-group-vertical > .btn:focus,
    .homepage-row .custom-text .btn-group-vertical > a:focus,
    .btn-group-vertical > .btn:active,
    .homepage-row .custom-text .btn-group-vertical > a:active,
    .btn-group-vertical > .btn.active,
    .homepage-row .custom-text .btn-group-vertical > a.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .homepage-row .custom-text .btn-group > a:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .homepage-row .custom-text .btn-group-sm > a + .dropdown-toggle-split, .homepage-row .custom-text a + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .homepage-row .custom-text .btn-group-lg > a + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .homepage-row .custom-text .btn-group-vertical > a,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .homepage-row .custom-text .btn-group-vertical > a:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }

.btn-group-toggle > .btn, .homepage-row .custom-text .btn-group-toggle > a,
.btn-group-toggle > .btn-group > .btn,
.homepage-row .custom-text .btn-group-toggle > .btn-group > a {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .homepage-row .custom-text .btn-group-toggle > a input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .homepage-row .custom-text .btn-group-toggle > a input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .homepage-row .custom-text .btn-group-toggle > .btn-group > a input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .homepage-row .custom-text .btn-group-toggle > .btn-group > a input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .homepage-row .custom-text a, .homepage-row .custom-text .input-group-prepend a,
  .input-group-append .btn,
  .input-group-append .homepage-row .custom-text a,
  .homepage-row .custom-text .input-group-append a {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .homepage-row .custom-text a:focus, .homepage-row .custom-text .input-group-prepend a:focus,
    .input-group-append .btn:focus,
    .input-group-append .homepage-row .custom-text a:focus,
    .homepage-row .custom-text .input-group-append a:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .homepage-row .custom-text a + .btn, .homepage-row .custom-text .input-group-prepend a + .btn, .input-group-prepend .homepage-row .custom-text .btn + a, .homepage-row .custom-text .input-group-prepend .btn + a, .input-group-prepend .homepage-row .custom-text a + a, .homepage-row .custom-text .input-group-prepend a + a,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .homepage-row .custom-text a + .input-group-text,
  .homepage-row .custom-text .input-group-prepend a + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .homepage-row .custom-text .input-group-text + a,
  .homepage-row .custom-text .input-group-prepend .input-group-text + a,
  .input-group-append .btn + .btn,
  .input-group-append .homepage-row .custom-text a + .btn,
  .homepage-row .custom-text .input-group-append a + .btn,
  .input-group-append .homepage-row .custom-text .btn + a,
  .homepage-row .custom-text .input-group-append .btn + a,
  .input-group-append .homepage-row .custom-text a + a,
  .homepage-row .custom-text .input-group-append a + a,
  .input-group-append .btn + .input-group-text,
  .input-group-append .homepage-row .custom-text a + .input-group-text,
  .homepage-row .custom-text .input-group-append a + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .homepage-row .custom-text .input-group-text + a,
  .homepage-row .custom-text .input-group-append .input-group-text + a {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6562rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.39;
  color: #2B3C4E;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #DDDDDD; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.homepage-row .custom-text .input-group-lg > .input-group-prepend > a,
.input-group-lg > .input-group-append > .btn,
.homepage-row .custom-text .input-group-lg > .input-group-append > a {
  padding: 0.5rem 1rem;
  font-size: 0.99995rem;
  line-height: 1.5; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.homepage-row .custom-text .input-group-sm > .input-group-prepend > a,
.input-group-sm > .input-group-append > .btn,
.homepage-row .custom-text .input-group-sm > .input-group-append > a {
  padding: 0.25rem 0.5rem;
  font-size: 0.74996rem;
  line-height: 1.5; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.5rem; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFF;
    border-color: #F4793B;
    background-color: #F4793B; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #fbccb4; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFF;
    background-color: #fdede4;
    border-color: #fdede4; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #FFF;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #F4793B;
  background-color: #F4793B; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(244, 121, 59, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(244, 121, 59, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(244, 121, 59, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFF;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(244, 121, 59, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.39em + 1.3124rem + 2px);
  padding: 0.6562rem 2.5rem 0.6562rem 1.5rem;
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.39;
  color: #2B3C4E;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.5rem center/8px 10px;
  background-color: #FFF;
  border: 1px solid #DDDDDD;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #fbccb4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }
    .custom-select:focus::-ms-value {
      color: #2B3C4E;
      background-color: #FFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.5rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.74996rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.99995rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.39em + 1.3124rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.39em + 1.3124rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #fbccb4;
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.39em + 1.3124rem + 2px);
  padding: 0.6562rem 1.5rem;
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.39;
  color: #2B3C4E;
  background-color: #FFF;
  border: 1px solid #DDDDDD; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.39em + 1.3124rem);
    padding: 0.6562rem 1.5rem;
    line-height: 1.39;
    color: #2B3C4E;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #F4793B;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fdede4; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #DDDDDD;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #F4793B;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #fdede4; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #DDDDDD;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #F4793B;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #fdede4; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #DDDDDD; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #DDDDDD; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #DDDDDD; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #DDDDDD; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFF;
    border-color: #DDDDDD #DDDDDD #FFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #F4793B; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.40629rem;
  padding-bottom: 0.40629rem;
  margin-right: 1rem;
  font-size: 0.99995rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 0.99995rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1279.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1280px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 20px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -20px;
      margin-left: -20px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 0;
        margin-left: 20px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 20px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2B3C4E;
  background-color: #FFF;
  border: 1px solid #DDDDDD; }
  .page-link:hover {
    z-index: 2;
    color: #4f6f90;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #DDDDDD; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFF;
  background-color: #F4793B;
  border-color: #F4793B; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFF;
  border-color: #DDDDDD; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.99995rem;
  line-height: 1.5; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.74996rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .homepage-row .custom-text a .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.badge-primary {
  color: #FFF;
  background-color: #F4793B; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFF;
    background-color: #ef590d; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 121, 59, 0.5); }

.badge-secondary {
  color: #FFF;
  background-color: #673B15; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFF;
    background-color: #3d230c; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(103, 59, 21, 0.5); }

.badge-success {
  color: #FFF;
  background-color: #00A13A; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFF;
    background-color: #006e28; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 58, 0.5); }

.badge-info {
  color: #FFF;
  background-color: #1DA1F2; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFF;
    background-color: #0c85d0; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(29, 161, 242, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #FFD600; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ccab00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 214, 0, 0.5); }

.badge-danger {
  color: #FFF;
  background-color: #E30613; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFF;
    background-color: #b1050f; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(227, 6, 19, 0.5); }

.badge-light {
  color: #FFF;
  background-color: #808A95; }
  a.badge-light:hover, a.badge-light:focus {
    color: #FFF;
    background-color: #67717b; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 138, 149, 0.5); }

.badge-dark {
  color: #FFF;
  background-color: #2B3C4E; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFF;
    background-color: #19232d; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 60, 78, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #7f3f1f;
  background-color: #fde4d8;
  border-color: #fcd9c8; }
  .alert-primary hr {
    border-top-color: #fbc8b0; }
  .alert-primary .alert-link {
    color: #562b15; }

.alert-secondary {
  color: #361f0b;
  background-color: #e1d8d0;
  border-color: #d4c8bd; }
  .alert-secondary hr {
    border-top-color: #cabbae; }
  .alert-secondary .alert-link {
    color: #0c0702; }

.alert-success {
  color: #00541e;
  background-color: #ccecd8;
  border-color: #b8e5c8; }
  .alert-success hr {
    border-top-color: #a5deba; }
  .alert-success .alert-link {
    color: #00210c; }

.alert-info {
  color: #0f547e;
  background-color: #d2ecfc;
  border-color: #c0e5fb; }
  .alert-info hr {
    border-top-color: #a8dbf9; }
  .alert-info .alert-link {
    color: #0a3650; }

.alert-warning {
  color: #856f00;
  background-color: #fff7cc;
  border-color: #fff4b8; }
  .alert-warning hr {
    border-top-color: #fff09f; }
  .alert-warning .alert-link {
    color: #524400; }

.alert-danger {
  color: #76030a;
  background-color: #f9cdd0;
  border-color: #f7b9bd; }
  .alert-danger hr {
    border-top-color: #f4a2a7; }
  .alert-danger .alert-link {
    color: #440206; }

.alert-light {
  color: #43484d;
  background-color: #e6e8ea;
  border-color: #dbdee1; }
  .alert-light hr {
    border-top-color: #cdd1d5; }
  .alert-light .alert-link {
    color: #2b2f32; }

.alert-dark {
  color: #161f29;
  background-color: #d5d8dc;
  border-color: #c4c8cd; }
  .alert-dark hr {
    border-top-color: #b6bbc1; }
  .alert-dark .alert-link {
    color: #040608; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  text-align: center;
  white-space: nowrap;
  background-color: #F4793B;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #F6F6F6; }
  .list-group-item-action:active {
    color: #2B3C4E;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #FFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFF;
    background-color: #F4793B;
    border-color: #F4793B; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 1280px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #7f3f1f;
  background-color: #fcd9c8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #7f3f1f;
    background-color: #fbc8b0; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    background-color: #7f3f1f;
    border-color: #7f3f1f; }

.list-group-item-secondary {
  color: #361f0b;
  background-color: #d4c8bd; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #361f0b;
    background-color: #cabbae; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFF;
    background-color: #361f0b;
    border-color: #361f0b; }

.list-group-item-success {
  color: #00541e;
  background-color: #b8e5c8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #00541e;
    background-color: #a5deba; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFF;
    background-color: #00541e;
    border-color: #00541e; }

.list-group-item-info {
  color: #0f547e;
  background-color: #c0e5fb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0f547e;
    background-color: #a8dbf9; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFF;
    background-color: #0f547e;
    border-color: #0f547e; }

.list-group-item-warning {
  color: #856f00;
  background-color: #fff4b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856f00;
    background-color: #fff09f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFF;
    background-color: #856f00;
    border-color: #856f00; }

.list-group-item-danger {
  color: #76030a;
  background-color: #f7b9bd; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #76030a;
    background-color: #f4a2a7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFF;
    background-color: #76030a;
    border-color: #76030a; }

.list-group-item-light {
  color: #43484d;
  background-color: #dbdee1; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #43484d;
    background-color: #cdd1d5; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFF;
    background-color: #43484d;
    border-color: #43484d; }

.list-group-item-dark {
  color: #161f29;
  background-color: #c4c8cd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #161f29;
    background-color: #b6bbc1; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #161f29;
    border-color: #161f29; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFF;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #DDDDDD; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #DDDDDD; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1280px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.74996rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  text-align: center;
  background-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.74996rem;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #2B3C4E;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2B3C4E; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFF;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #F4793B !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ef590d !important; }

.bg-secondary {
  background-color: #673B15 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3d230c !important; }

.bg-success {
  background-color: #00A13A !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #006e28 !important; }

.bg-info {
  background-color: #1DA1F2 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0c85d0 !important; }

.bg-warning {
  background-color: #FFD600 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ccab00 !important; }

.bg-danger {
  background-color: #E30613 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #b1050f !important; }

.bg-light {
  background-color: #808A95 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #67717b !important; }

.bg-dark {
  background-color: #2B3C4E !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #19232d !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #DDDDDD !important; }

.border-top {
  border-top: 1px solid #DDDDDD !important; }

.border-right {
  border-right: 1px solid #DDDDDD !important; }

.border-bottom {
  border-bottom: 1px solid #DDDDDD !important; }

.border-left {
  border-left: 1px solid #DDDDDD !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #F4793B !important; }

.border-secondary {
  border-color: #673B15 !important; }

.border-success {
  border-color: #00A13A !important; }

.border-info {
  border-color: #1DA1F2 !important; }

.border-warning {
  border-color: #FFD600 !important; }

.border-danger {
  border-color: #E30613 !important; }

.border-light {
  border-color: #808A95 !important; }

.border-dark {
  border-color: #2B3C4E !important; }

.border-white {
  border-color: #FFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1280px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1280px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1280px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1280px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFF !important; }

.text-primary {
  color: #F4793B !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #d6500c !important; }

.text-secondary {
  color: #673B15 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #271708 !important; }

.text-success {
  color: #00A13A !important; }

a.text-success:hover, a.text-success:focus {
  color: #00551e !important; }

.text-info {
  color: #1DA1F2 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0b76b8 !important; }

.text-warning {
  color: #FFD600 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b39600 !important; }

.text-danger {
  color: #E30613 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #98040d !important; }

.text-light {
  color: #808A95 !important; }

a.text-light:hover, a.text-light:focus {
  color: #5b646d !important; }

.text-dark {
  color: #2B3C4E !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #10161d !important; }

.text-body {
  color: #2B3C4E !important; }

.text-muted {
  color: #808A95 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1280px !important; }
  .container {
    min-width: 1280px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #DDDDDD !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #DDDDDD; }
  .table .thead-dark th {
    color: inherit;
    border-color: #DDDDDD; } }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Poppins Regular "), local("Poppins-Regular"), url("~typeface-poppins/files/poppins-latin-400.woff2") format("woff2"), url("~typeface-poppins/files/poppins-latin-400.woff") format("woff"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local("Poppins Regular italic"), local("Poppins-Regularitalic"), url("~typeface-poppins/files/poppins-latin-400italic.woff2") format("woff2"), url("~typeface-poppins/files/poppins-latin-400italic.woff") format("woff"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Poppins Medium "), local("Poppins-Medium"), url("~typeface-poppins/files/poppins-latin-500.woff2") format("woff2"), url("~typeface-poppins/files/poppins-latin-500.woff") format("woff"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local("Poppins Medium italic"), local("Poppins-Mediumitalic"), url("~typeface-poppins/files/poppins-latin-500italic.woff2") format("woff2"), url("~typeface-poppins/files/poppins-latin-500italic.woff") format("woff"); }

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Poppins Bold "), local("Poppins-Bold"), url("~typeface-poppins/files/poppins-latin-700.woff2") format("woff2"), url("~typeface-poppins/files/poppins-latin-700.woff") format("woff"); }

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local("Poppins Bold italic"), local("Poppins-Bolditalic"), url("~typeface-poppins/files/poppins-latin-700italic.woff2") format("woff2"), url("~typeface-poppins/files/poppins-latin-700italic.woff") format("woff"); }

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local("Archivo Bold"), local("Archivo-Bold"), url("~typeface-archivo/files/archivo-latin-700.woff2") format("woff2"), url("~typeface-archivo/files/archivo-latin-700.woff") format("woff"); }

@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local("Archivo Bold italic"), local("Archivo-Bolditalic"), url("~typeface-archivo/files/archivo-latin-700italic.woff2") format("woff2"), url("~typeface-archivo/files/archivo-latin-700italic.woff") format("woff"); }

@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Archivo Regular "), local("Archivo-Regular"), url("~typeface-archivo/files/archivo-latin-400.woff2") format("woff2"), url("~typeface-archivo/files/archivo-latin-400.woff") format("woff"); }

@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local("Archivo Regular italic"), local("Archivo-Regularitalic"), url("~typeface-archivo/files/archivo-latin-400italic.woff2") format("woff2"), url("~typeface-archivo/files/archivo-latin-400italic.woff") format("woff"); }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format("woff2"), url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format("woff"), url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

body,
html {
  height: 100%; }

body {
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

ul {
  list-style: none;
  padding-left: 0; }

.dropdown-item:focus,
.dropdown-item:hover {
  background: none; }

.color,
.custom-checkbox input[type="checkbox"] + span.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  margin: 0.3125rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain; }
  .color.active, .color:hover,
  .custom-checkbox input[type="checkbox"] + span.color.active,
  .custom-checkbox input[type="checkbox"] + span.color:hover {
    border: 2px solid #2B3C4E; }

.facet-label.active .custom-checkbox span.color, .facet-label:hover .custom-checkbox span.color {
  border: 2px solid #2B3C4E; }

small.value {
  font-size: 0.8125rem; }

.text-muted {
  font-size: 0.875rem; }

.done {
  color: #00A13A;
  display: inline-block;
  padding: 0 0.8125rem;
  margin-right: 1.563rem; }

.thumb-mask > .mask {
  position: relative;
  width: 3.438rem;
  height: 3.438rem;
  overflow: hidden;
  border: #F6F6F6 1px solid;
  margin: 0.75rem 0; }
  .thumb-mask > .mask img {
    width: 55px;
    height: 55px; }

.definition-list dl {
  display: flex;
  flex-wrap: wrap; }
  .definition-list dl dt {
    font-weight: normal; }
  .definition-list dl dd,
  .definition-list dl dt {
    flex: 0 0 45%;
    background: #F6F6F6;
    padding: 0.625rem;
    margin: 0.125rem; }
    .definition-list dl dd:nth-of-type(even),
    .definition-list dl dt:nth-of-type(even) {
      background: #F6F6F6; }

.help-block {
  margin-top: 0.75rem; }

.btn.disabled, .homepage-row .custom-text a.disabled,
.btn.disabled:hover,
.homepage-row .custom-text a.disabled:hover {
  background: #DDDDDD; }

.alert-warning .material-icons {
  color: #FFD600;
  font-size: 2rem;
  margin-right: 0.75rem;
  padding-top: 0.625rem; }

.alert-warning .alert-text {
  font-size: 0.9375rem;
  padding-top: 0.75rem; }

.alert-warning .alert-link {
  border-radius: 2px;
  border-width: 2px;
  margin-left: 0.75rem;
  padding: 0.625rem 0.875rem;
  font-weight: 600;
  font-size: 0.8125rem;
  color: #F4793B; }

.alert-warning ul li:last-child .alert-link {
  color: white; }

.alert-warning .warning-buttons {
  margin-top: 0.625rem; }

.btn-warning {
  transition: all 0.4s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn-warning {
      transition: none; } }

.btn-tertiary-outline {
  color: #F4793B;
  background-image: none;
  background-color: transparent;
  border-color: #F4793B;
  border: 0.15rem solid #F4793B;
  transition: all 0.4s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn-tertiary-outline {
      transition: none; } }
  .btn-tertiary-outline:hover {
    border-color: #f36823;
    color: #f36823; }

.alert {
  font-size: 0.8125rem; }

.nav-item .nav-link,
.nav-item .nav-separtor {
  color: #808A95;
  font-weight: bold; }
  .nav-item .nav-link.active,
  .nav-item .nav-separtor.active {
    color: #2B3C4E; }

.separator {
  margin: 0;
  border-color: rgba(0, 0, 0, 0.25); }

.btn, .homepage-row .custom-text a {
  text-transform: uppercase;
  letter-spacing: 1px; }
  .btn .material-icons, .homepage-row .custom-text a .material-icons {
    margin-left: 0.75rem; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #808A95; }

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #808A95; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #808A95; }

input:-moz-placeholder, textarea:-moz-placeholder {
  color: #808A95; }

input:active::-webkit-input-placeholder, textarea:active::-webkit-input-placeholder {
  color: #2B3C4E; }

input:active::-moz-placeholder, textarea:active::-moz-placeholder {
  color: #2B3C4E; }

input:active:-ms-input-placeholder, textarea:active:-ms-input-placeholder {
  color: #2B3C4E; }

input:active:-moz-placeholder, textarea:active:-moz-placeholder {
  color: #2B3C4E; }

.form-control-comment {
  margin-left: 1rem;
  color: #808A95;
  font-weight: 500; }

.form-control-submit.disabled {
  background: #1DA1F2;
  color: white; }

.form-group.has-error input,
.form-group.has-error select {
  outline: 0.1875rem solid #E30613; }

.form-group.has-error .help-block {
  color: #E30613; }

.group-span-filestyle label {
  margin: 0; }

.group-span-filestyle .btn-default, .group-span-filestyle .bootstrap-touchspin .btn-touchspin, .bootstrap-touchspin .group-span-filestyle .btn-touchspin {
  background: #F4793B;
  color: white;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 0.875rem;
  padding: 0.5rem 1rem; }

.bootstrap-touchspin {
  width: auto;
  float: left;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2); }
  .bootstrap-touchspin input:focus {
    outline: none; }
  .bootstrap-touchspin input.form-control, .bootstrap-touchspin input.input-group {
    color: #2B3C4E;
    background-color: white;
    height: calc(1.39em + 1.3124rem + 2px);
    padding: 0.175rem 0.5rem;
    width: 3rem;
    border: 1px solid #DDDDDD;
    float: left; }
  .bootstrap-touchspin .btn-touchspin {
    background-color: white;
    border: 1px solid #DDDDDD;
    height: calc(1.39em + 1.3124rem + 2px)/20.0625rem; }
    .bootstrap-touchspin .btn-touchspin:hover {
      background-color: #F6F6F6; }
  .bootstrap-touchspin .input-group-btn-vertical {
    color: #2B3C4E;
    width: auto;
    float: left; }
    .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
      border-radius: 0; }
    .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
      border-radius: 0; }
    .bootstrap-touchspin .input-group-btn-vertical .touchspin-up:after {
      content: "\E5CE"; }
    .bootstrap-touchspin .input-group-btn-vertical .touchspin-down:after {
      content: "\E5CF"; }
    .bootstrap-touchspin .input-group-btn-vertical i {
      top: 0.0625rem;
      left: 0.1875rem;
      font-size: 0.9375rem; }

.custom-radio {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: #DDDDDD 2px solid;
  background: white;
  margin-right: 1.25rem; }
  .custom-radio input[type="radio"] {
    opacity: 0;
    cursor: pointer; }
  .custom-radio input[type="radio"]:checked + span {
    display: block;
    background-color: #F4793B;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    left: 0.125rem;
    top: 0.125rem; }
  .custom-radio input[type="radio"]:focus + span {
    border-color: #DDDDDD; }

.custom-checkbox {
  position: relative; }
  .custom-checkbox input[type="checkbox"] {
    margin-top: 0.25rem;
    opacity: 0;
    cursor: pointer;
    position: absolute; }
    .custom-checkbox input[type="checkbox"] + span {
      margin-right: 3px;
      display: inline-block;
      width: 0.9375rem;
      height: 0.9375rem;
      vertical-align: middle;
      cursor: pointer;
      border: 2px #2B3C4E solid; }
      .custom-checkbox input[type="checkbox"] + span .checkbox-checked {
        display: none;
        margin: -0.25rem -0.125rem;
        font-size: 1.1rem;
        color: #2B3C4E; }
    .custom-checkbox input[type="checkbox"]:checked + span .checkbox-checked {
      display: block; }
    .custom-checkbox input[type="checkbox"]:focus + span {
      border-color: #DDDDDD; }
  .custom-checkbox label {
    text-align: left; }

select.form-control {
  -webkit-appearance: none; }

.form-control-label {
  width: calc(100% - 56px);
  margin-left: 28px;
  margin-top: 20px;
  margin-right: 28px;
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  font-weight: bold; }

.form-fields.structure {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .form-fields.structure .form-group {
    flex: 0 0 100%; }
  @media (min-width: 768px) {
    .form-fields.structure .company-field, .form-fields.structure .vat_number-field,
    .form-fields.structure .firstname-field, .form-fields.structure .lastname-field,
    .form-fields.structure .email-field, .form-fields.structure .birthday-field,
    .form-fields.structure .postcode-field, .form-fields.structure .city-field,
    .form-fields.structure .id_state-field, .form-fields.structure .id_country-field,
    .form-fields.structure .phone-field {
      flex: 0 0 48%; } }

.ps-alert-error {
  margin-bottom: 0; }

.ps-alert-error .item,
.ps-alert-success .item {
  align-items: center;
  border: 2px #E30613 solid;
  display: flex;
  background-color: #E30613;
  margin-bottom: 1rem; }
  .ps-alert-error .item i,
  .ps-alert-success .item i {
    border: 15px #E30613 solid;
    display: flex; }
    .ps-alert-error .item i svg,
    .ps-alert-success .item i svg {
      background-color: #E30613;
      width: 24px;
      height: 24px; }
  .ps-alert-error .item p,
  .ps-alert-success .item p {
    background-color: #FFF;
    margin: 0;
    padding: 18px 20px 18px 20px;
    width: 100%; }

.ps-alert-success {
  padding: 0.25rem 0.25rem 2.75rem 0.25rem; }
  .ps-alert-success .item {
    border-color: #00A13A;
    background-color: #00A13A; }
    .ps-alert-success .item i {
      border-color: #00A13A; }
      .ps-alert-success .item i svg {
        background-color: #00A13A; }

.ui-notifications .alert {
  padding-left: 0;
  padding-right: 0;
  text-align: center; }
  main > .ui-notifications .alert {
    border-left: 0;
    border-right: 0; }
    main > .ui-notifications .alert:first-child {
      margin-top: -1px; }

.ui-notifications ul {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .ui-notifications ul {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .ui-notifications ul {
      max-width: 720px; } }
  @media (min-width: 1280px) {
    .ui-notifications ul {
      max-width: 1200px; } }
  @media (min-width: 1440px) {
    .ui-notifications ul {
      max-width: 1420px; } }

.dropdown {
  color: #DDDDDD; }
  .dropdown:hover .expand-more {
    color: #F4793B; }
  .dropdown .expand-more {
    color: #2B3C4E;
    cursor: pointer;
    user-select: none; }
  .dropdown .active {
    max-height: 200px;
    overflow-y: hidden;
    visibility: visible; }
  .dropdown select {
    -moz-appearance: none;
    border: 0 none;
    outline: 0 none;
    color: #2B3C4E;
    background: white; }

.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
  text-decoration: none;
  color: #F4793B; }

.page-not-found {
  width: 100% !important;
  margin: 0 !important;
  box-shadow: none !important;
  max-width: 100% !important; }
  .page-not-found header {
    margin-top: 2rem;
    text-align: center; }
  .page-not-found .search-widget form {
    position: relative;
    width: 60%;
    display: block !important;
    margin-left: auto;
    margin-right: auto; }

.page-pagenotfound h1 {
  text-align: center; }

.search-widget {
  display: block; }
  @media (min-width: 768px) {
    .search-widget {
      min-width: 15.63rem; } }
  .search-widget form {
    position: relative; }
    .search-widget form input[type=text] {
      min-width: 15.625rem;
      font-size: 0.875rem;
      line-height: 1.8;
      height: calc(1.8em + 1.3124rem + 2px); }
    .search-widget form button[type=submit] {
      position: absolute;
      bottom: 0.1875rem;
      right: 0.1875rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }

.header-top .search-widget {
  width: 100%; }

#pagenotfound .page-content #search_widget {
  width: 100%; }

.page-not-found .search-widget form {
  display: inline-block; }

.top-menu {
  position: relative;
  margin: 0.75rem 0 0; }
  @media (min-width: 768px) {
    .top-menu {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 768px) {
    .top-menu > li {
      display: flex;
      align-items: center;
      justify-content: center; } }
  @media (min-width: 768px) {
    [data-depth="0"].top-menu > li:first-child > a {
      padding-left: 0; }
    [data-depth="0"].top-menu > li:last-child > a {
      padding-right: 0; }
    [data-depth="0"].top-menu > li:hover > .sub-menu, [data-depth="0"].top-menu > li:focus > .sub-menu {
      display: block !important;
      z-index: 19; } }
  .top-menu[data-depth="1"] {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -0.5rem; }
    @media (min-width: 768px) {
      .top-menu[data-depth="1"] {
        margin: 0 -1.125rem; } }
    .top-menu[data-depth="1"] > li {
      width: 50%;
      flex: 0 0 50%;
      padding: 0.5rem 0.5rem;
      text-align: center; }
      @media (min-width: 768px) {
        .top-menu[data-depth="1"] > li {
          flex: 0 0 25%;
          max-width: 25%;
          padding-left: 1.125rem;
          padding-right: 1.125rem; } }
      @media (min-width: 1280px) {
        .top-menu[data-depth="1"] > li {
          flex: 0 0 16.66%;
          max-width: 16.66%; } }
  .top-menu a {
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.333;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #2B3C4E;
    padding: 0.25rem 0.75rem; }
    .top-menu a:hover, .top-menu a:focus {
      color: #4f6f90;
      text-decoration: none; }
  .top-menu .dropdown-submenu {
    padding: 0;
    width: 100%; }
  .top-menu .dropdown-item {
    display: block; }
    @media (max-width: 767.98px) {
      .top-menu .dropdown-item {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0; } }
  .top-menu .navbar-toggler {
    padding: 0 0 0 0.125rem;
    margin-top: -0.25em; }
    .top-menu .navbar-toggler .material-icons {
      font-size: 18px; }
  .top-menu .sub-menu {
    display: none;
    background: #FFF;
    padding: 1rem 0;
    border-bottom: 1px solid #DDDDDD;
    margin-bottom: 0.5rem; }
    @media (min-width: 768px) {
      .top-menu .sub-menu {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 18;
        width: 100vw;
        padding: 2.5rem 0;
        margin-bottom: 0;
        box-shadow: rgba(0, 0, 0, 0.05) 4px 10px 20px; } }
    @media (max-width: 767.98px) {
      .top-menu .sub-menu.show {
        display: block; } }
    .top-menu .sub-menu a:hover, .top-menu .sub-menu a:focus {
      color: #F4793B; }
    @media (max-width: 767.98px) {
      .top-menu .sub-menu .container {
        padding-left: 0;
        padding-right: 0; } }
  .top-menu .sub-menu-description {
    margin: 0 auto 0.75rem;
    font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    line-height: 1.5;
    color: #808A95; }
    @media (min-width: 768px) {
      .top-menu .sub-menu-description {
        max-width: 80%;
        text-align: center; } }
    .top-menu .sub-menu-description h1, .top-menu .sub-menu-description h2, .top-menu .sub-menu-description h3, .top-menu .sub-menu-description h4, .top-menu .sub-menu-description h5, .top-menu .sub-menu-description h6 {
      font-size: 1rem;
      line-height: 1.0833;
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 700;
      color: #2B3C4E;
      margin-bottom: 0.41em; }
      @media (min-width: 768px) {
        .top-menu .sub-menu-description h1, .top-menu .sub-menu-description h2, .top-menu .sub-menu-description h3, .top-menu .sub-menu-description h4, .top-menu .sub-menu-description h5, .top-menu .sub-menu-description h6 {
          font-size: 1.5rem; } }
      .top-menu .sub-menu-description h1 *, .top-menu .sub-menu-description h2 *, .top-menu .sub-menu-description h3 *, .top-menu .sub-menu-description h4 *, .top-menu .sub-menu-description h5 *, .top-menu .sub-menu-description h6 * {
        font-weight: inherit !important;
        font-size: inherit !important;
        font-family: inherit !important;
        color: inherit !important; }
    .top-menu .sub-menu-description h1, .top-menu .sub-menu-description h2, .top-menu .sub-menu-description h3 {
      text-transform: uppercase; }
    .top-menu .sub-menu-description h2 {
      font-size: 0.875rem; }
      @media (min-width: 768px) {
        .top-menu .sub-menu-description h2 {
          font-size: 1.25rem; } }
    .top-menu .sub-menu-description h3 {
      font-size: 1rem; }
      @media (min-width: 768px) {
        .top-menu .sub-menu-description h3 {
          font-size: 1.125rem; } }
    .top-menu .sub-menu-description h4, .top-menu .sub-menu-description h5, .top-menu .sub-menu-description h6 {
      font-size: 0.75rem; }
      @media (min-width: 768px) {
        .top-menu .sub-menu-description h4, .top-menu .sub-menu-description h5, .top-menu .sub-menu-description h6 {
          font-size: 1rem; } }
    .top-menu .sub-menu-description p * {
      font-weight: inherit !important;
      font-size: inherit !important;
      font-family: inherit !important;
      color: inherit !important; }
  .top-menu .menu-icon {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 7.5rem;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
    .top-menu .menu-icon:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%; }

.checkout-title {
  margin-bottom: 0; }
  .checkout-title .checkout-step {
    display: inline-block;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.0935;
    color: #808A95;
    cursor: default; }
    @media (min-width: 576px) {
      .checkout-title .checkout-step {
        font-size: 1.125rem; } }
    @media (min-width: 1280px) {
      .checkout-title .checkout-step {
        font-size: 1.25rem; } }
    @media (min-width: 1440px) {
      .checkout-title .checkout-step {
        font-size: 1.5rem; } }
    .checkout-title .checkout-step.-reachable {
      cursor: pointer; }
    .checkout-title .checkout-step.-unreachable {
      color: #DDDDDD; }
    .checkout-title .checkout-step.-current {
      color: #2B3C4E;
      cursor: default; }
    .checkout-title .checkout-step .material-icons {
      font-size: 18px;
      color: #808A95; }
    .checkout-title .checkout-step:last-child .material-icons {
      display: none; }

.cart-grid-body .step-header h3 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.8571;
  color: #2B3C4E;
  margin-bottom: 1rem; }

.cart-grid-body .step-header p {
  font-size: 1.125rem;
  line-height: 2; }

.cart-grid-body .checkout-step a {
  color: #F4793B; }
  .cart-grid-body .checkout-step a:hover, .cart-grid-body .checkout-step a:focus {
    text-decoration: underline; }

.cart-grid-body .checkout-step .content {
  display: none; }
  @media (max-width: 1279.98px) {
    .cart-grid-body .checkout-step .content button[type="submit"] {
      transform: rotateX(90deg);
      margin-bottom: -5rem; } }

.cart-grid-body .checkout-step.-current .content, .cart-grid-body .checkout-step.-current.-reachable.-complete .content {
  display: block; }

.cart-grid-body .checkout-step.-reachable.-complete .content {
  display: none; }

.cart-grid-body .checkout-step small {
  color: #808A95; }

#checkout section.checkout-step .not-allowed {
  cursor: not-allowed;
  opacity: 0.5; }

#checkout section.checkout-step .radio-field {
  margin-top: 1.875rem; }
  #checkout section.checkout-step .radio-field label {
    display: inline; }

#checkout section.checkout-step .checkout-checkbox {
  margin-top: 1.5rem; }

#checkout section.checkout-step .form-footer {
  text-align: center; }

#checkout section.checkout-step .conditions-to-approve {
  margin-top: 3.5rem; }
  #checkout section.checkout-step .conditions-to-approve > p {
    margin-bottom: 1rem; }

#checkout section.checkout-step .payment-options .custom-radio {
  margin-right: 0.875rem; }

#checkout section.checkout-step .payment-options .payment-option {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; }

#checkout section.checkout-step .payment-options form {
  margin-right: 1rem; }

#checkout section.checkout-step .payment-options .payment-label {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 700;
  font-size: 1.125rem;
  cursor: pointer; }

#checkout section.checkout-step .payment-options .payment-logo {
  margin-right: 0.5rem;
  max-height: 2.5rem;
  width: auto; }

#checkout section.checkout-step .address-selector {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

#checkout section.checkout-step .address-item {
  display: flex;
  flex-direction: column;
  background: #F6F6F6;
  flex: 0 0 100%;
  margin-bottom: 1rem;
  border: 2px solid transparent; }
  @media (min-width: 768px) {
    #checkout section.checkout-step .address-item {
      flex: 0 0 49%; } }
  #checkout section.checkout-step .address-item.selected {
    background: white;
    border: #F4793B 2px solid; }

#checkout section.checkout-step .address-alias {
  display: inline-block;
  font-weight: 700;
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 1px; }

#checkout section.checkout-step .address {
  margin-left: 1.563rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375; }

#checkout section.checkout-step .radio-block {
  padding: 0.9375rem;
  text-align: left;
  cursor: pointer;
  display: block;
  margin: 0; }

#checkout section.checkout-step .custom-radio {
  margin-right: 0; }
  #checkout section.checkout-step .custom-radio input[type="radio"] {
    height: 1.25rem;
    width: 1.25rem; }

#checkout section.checkout-step .delete-address,
#checkout section.checkout-step .edit-address {
  color: #808A95;
  display: inline-block;
  margin: 0 0.5rem; }
  #checkout section.checkout-step .delete-address .delete,
  #checkout section.checkout-step .delete-address .edit,
  #checkout section.checkout-step .edit-address .delete,
  #checkout section.checkout-step .edit-address .edit {
    font-size: 0.875rem; }
  #checkout section.checkout-step .delete-address i,
  #checkout section.checkout-step .edit-address i {
    margin-right: 0.25rem; }

#checkout section.checkout-step .address-footer {
  text-align: center;
  margin-top: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem; }

#checkout section.checkout-step #delivery-addresses,
#checkout section.checkout-step #invoice-addresses {
  margin-top: 1.25rem; }

#checkout section.checkout-step .add-address {
  margin-top: 1.25rem; }
  #checkout section.checkout-step .add-address a {
    color: #2B3C4E; }
    #checkout section.checkout-step .add-address a i {
      font-size: 0.99995rem; }

#checkout section.checkout-step .delivery-option {
  display: flex;
  align-items: center;
  background: #F6F6F6;
  padding: 1rem;
  margin-bottom: 1rem;
  min-height: 4.5rem; }
  #checkout section.checkout-step .delivery-option .custom-radio {
    flex: 0 0 auto;
    margin-right: 1rem;
    cursor: pointer; }
  #checkout section.checkout-step .delivery-option .delivery-option-body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    cursor: pointer; }
    @media (min-width: 768px) {
      #checkout section.checkout-step .delivery-option .delivery-option-body {
        flex-wrap: nowrap; } }
  #checkout section.checkout-step .delivery-option .carrier-info {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 700;
    font-size: 1rem; }
    @media (min-width: 768px) {
      #checkout section.checkout-step .delivery-option .carrier-info {
        margin-right: 1rem;
        margin-bottom: 0; } }
  #checkout section.checkout-step .delivery-option .carrier-logo {
    margin-right: 0.5rem; }
    #checkout section.checkout-step .delivery-option .carrier-logo img {
      width: 100%;
      max-width: 40px;
      height: auto; }
  #checkout section.checkout-step .delivery-option .carrier-delay {
    width: 100%;
    margin-bottom: 0.5rem; }
    @media (min-width: 768px) {
      #checkout section.checkout-step .delivery-option .carrier-delay {
        margin: 0 0.5rem 0 auto;
        width: 50%; } }
  #checkout section.checkout-step .delivery-option .carrier-price {
    width: 100%; }
    @media (min-width: 768px) {
      #checkout section.checkout-step .delivery-option .carrier-price {
        text-align: right;
        width: 50%; } }

#checkout section.checkout-step .order-options {
  margin-top: 2rem; }
  #checkout section.checkout-step .order-options textarea.form-control {
    margin-top: 0.8rem; }
  #checkout section.checkout-step .order-options .custom-checkbox {
    display: flex;
    margin-top: 1rem; }
    #checkout section.checkout-step .order-options .custom-checkbox input + span {
      flex: 0 0 auto;
      margin-right: 1rem; }
    #checkout section.checkout-step .order-options .custom-checkbox label {
      cursor: pointer; }

#checkout section.checkout-step #customer-form,
#checkout section.checkout-step #delivery-address,
#checkout section.checkout-step #invoice-address {
  margin-left: 0.3125rem;
  margin-top: 1.563rem; }
  #checkout section.checkout-step #customer-form .form-control-label,
  #checkout section.checkout-step #delivery-address .form-control-label,
  #checkout section.checkout-step #invoice-address .form-control-label {
    text-align: left; }
  #checkout section.checkout-step #customer-form .radio-inline,
  #checkout section.checkout-step #delivery-address .radio-inline,
  #checkout section.checkout-step #invoice-address .radio-inline {
    padding: 0; }

#checkout .additional-information {
  margin: 1.25rem 0; }
  @media (min-width: 768px) {
    #checkout .additional-information {
      margin-left: 2.875rem;
      margin-right: 2.875rem; } }

#checkout .condition-label {
  margin-left: 2.5rem;
  margin-top: 0.625rem; }
  #checkout .condition-label label {
    text-align: inherit; }

#checkout .cancel-address {
  margin: 0.625rem;
  display: block;
  color: #808A95;
  text-decoration: underline; }

#checkout #cart-summary-product-list {
  font-size: 0.74996rem; }
  #checkout #cart-summary-product-list img {
    border: #F6F6F6 1px solid;
    width: 3.125rem; }
  #checkout #cart-summary-product-list .media-body {
    vertical-align: middle; }

#checkout #order-summary-content {
  padding-top: 0.9375rem; }
  #checkout #order-summary-content h4.h4 {
    margin-top: 0.625rem;
    margin-bottom: 1.25rem; }
  #checkout #order-summary-content h4.h5 {
    color: #2B3C4E; }
  #checkout #order-summary-content h4.addresshead {
    margin-top: 0.1875rem; }
  #checkout #order-summary-content .card {
    box-shadow: none; }
    #checkout #order-summary-content .card .card-body {
      font-size: 1rem;
      line-height: 1.375; }
  #checkout #order-summary-content .summary-section {
    padding: 1rem 0;
    border-top: 1px solid #F6F6F6; }
  #checkout #order-summary-content .summary-selected-carrier {
    margin-bottom: 0.75rem;
    background: none;
    border: 1px solid #DDDDDD;
    border-radius: 0;
    padding: 1rem; }
    #checkout #order-summary-content .summary-selected-carrier .delivery-option-body {
      cursor: default; }
  #checkout #order-summary-content .step-edit {
    display: inline;
    color: #808A95; }
  #checkout #order-summary-content .step-edit:hover {
    cursor: pointer;
    color: #F4793B; }
  #checkout #order-summary-content a .step-edit {
    color: #808A95; }

#checkout #gift_message, #checkout #delivery {
  max-width: 100%; }
  #checkout #gift_message textarea, #checkout #delivery textarea {
    max-width: 100%;
    margin-bottom: 10px; }

/**** ORDER CONFIRMATION *****/
#order-details > .card-title {
  margin-bottom: 1rem; }

#order-details ul {
  margin-bottom: 0.875rem; }
  #order-details ul li {
    margin-bottom: 0.75rem; }

#order-items table {
  width: 100%; }
  #order-items table tr {
    height: 1rem; }
    #order-items table tr td.label {
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.333;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #2B3C4E; }
      #order-items table tr td.label small {
        font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.1428;
        color: #808A95; }
    #order-items table tr td.value {
      font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      color: #2B3C4E; }
    #order-items table tr td:last-child {
      text-align: right; }
    #order-items table tr.order-total td.label, #order-items table tr.order-total td.value {
      font-size: 1.5rem;
      line-height: 0.92; }
    #order-items table tr.order-total td.label {
      text-transform: none;
      letter-spacing: normal; }
    #order-items table tr.order-total td.value {
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 700; }

#order-items .order-line {
  margin-top: 1rem;
  display: flex;
  align-items: center; }

#order-items .product-image {
  margin-right: 0.5rem; }
  @media (min-width: 768px) {
    #order-items .product-image {
      margin-right: 1rem; } }
  #order-items .product-image img {
    width: 5rem;
    height: auto; }
    @media (min-width: 768px) {
      #order-items .product-image img {
        width: 7.5rem; } }

#order-items .details {
  max-width: calc(100% - 5rem - 6%); }
  @media (min-width: 768px) {
    #order-items .details {
      max-width: 100%;
      margin-right: 1rem; } }
  #order-items .details .product-category {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1428;
    color: #808A95;
    margin-bottom: 0.25em; }
    @media (min-width: 768px) {
      #order-items .details .product-category {
        margin-bottom: 0.5em; } }
  #order-items .details .product-title {
    display: block;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    color: #2B3C4E;
    margin-bottom: 0.25em; }
    @media (min-width: 768px) {
      #order-items .details .product-title {
        font-size: 1.125rem;
        line-height: 1.1111;
        margin-bottom: 0.875em; } }
  #order-items .details .current-price {
    display: block;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1;
    color: #2B3C4E;
    margin: 0; }
    @media (min-width: 768px) {
      #order-items .details .current-price {
        font-size: 1.125rem;
        line-height: 1.1111; } }
  #order-items .details .customizations {
    margin-top: 1rem; }

#order-items .info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem; }
  @media (min-width: 768px) {
    #order-items .info {
      margin-top: 0;
      margin-left: auto;
      flex: 0 0 auto;
      width: auto; } }
  #order-items .info .product-pieces {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #2B3C4E; }
    #order-items .info .product-pieces:before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(../../../img/pod.svg) no-repeat center;
      background-size: contain;
      vertical-align: middle;
      margin-right: 6px; }
  #order-items .info .product-line-info {
    position: relative;
    padding: 0 0.5rem; }
    @media (min-width: 768px) {
      #order-items .info .product-line-info {
        padding: 0 1rem; } }
    #order-items .info .product-line-info:before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 0;
      width: 1px;
      height: 3rem;
      margin-top: -1.5rem;
      vertical-align: middle;
      background-color: #808A95; }
      @media (min-width: 768px) {
        #order-items .info .product-line-info:before {
          height: 4.75rem;
          margin-top: -2.375rem; } }
    #order-items .info .product-line-info:first-child {
      padding-left: 0; }
      #order-items .info .product-line-info:first-child:before {
        display: none; }
    #order-items .info .product-line-info:last-child {
      padding-right: 0; }
  #order-items .info .qty strong {
    font-weight: 500; }

#order-items .order-totals {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #F6F6F6; }

#order-confirmation #registration-form {
  width: 50%;
  margin: 0 auto 1rem; }

.js-payment-binary {
  display: none; }
  .js-payment-binary .accept-cgv {
    display: none; }
  .js-payment-binary.disabled {
    opacity: 0.6;
    cursor: not-allowed; }
    .js-payment-binary.disabled::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none; }
    .js-payment-binary.disabled .accept-cgv {
      display: block; }

/*** SOME GENERIC STYLES ***/
.table-labeled th {
  vertical-align: middle; }

.table-labeled td {
  vertical-align: middle; }

.table-labeled .label {
  font-weight: 400;
  border-radius: 3px;
  font-size: inherit;
  padding: 0.25rem 0.375rem;
  margin: 0.125rem;
  color: white;
  white-space: nowrap; }

.page-order .table {
  margin-bottom: 0; }

.page-order table th {
  padding: 0.5rem; }

.page-order table td {
  padding: 0.5rem; }

.page-order table thead th {
  text-align: center; }

#authentication .tooltip.tooltip-bottom {
  padding: 0;
  margin: 0; }

#authentication .custom-checkbox {
  display: flex; }
  #authentication .custom-checkbox span {
    flex: 0 0 0.9375rem; }
  #authentication .custom-checkbox label {
    padding-left: 0.625rem; }

#identity .radio-inline,
#authentication .radio-inline {
  padding: 0; }
  #identity .radio-inline .custom-radio,
  #authentication .radio-inline .custom-radio {
    margin-right: 0; }

/*** Most of the customer accpunt pages ***/
.page-customer-account #content {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: white;
  padding: 1rem;
  font-size: 0.74996rem;
  color: #808A95; }
  .page-customer-account #content .order-actions a {
    padding: 0 0.125rem; }
  .page-customer-account #content .forgot-password {
    text-align: center;
    font-size: 0.74996rem;
    margin-top: 1rem;
    color: #808A95;
    padding-bottom: 0.9375rem; }
  .page-customer-account #content .no-account {
    text-align: center;
    font-size: 0.875rem; }

/*** Login page ***/
#authentication h1.authentication-title, #password h1.authentication-title {
  margin-top: 4rem;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 4rem; }
  #authentication h1.authentication-title.registration, #password h1.authentication-title.registration {
    margin-bottom: 1rem; }

#authentication .register-form hr, #password .register-form hr {
  height: 1px;
  background-color: #F6F6F6;
  width: 100%;
  border: 0; }

#authentication .register-form textarea, #password .register-form textarea {
  height: 10rem;
  resize: none; }

#authentication .login-image, #password .login-image {
  object-fit: cover;
  width: 100%;
  height: 100%; }
  @media (max-width: 1279.98px) {
    #authentication .login-image, #password .login-image {
      height: 10rem; } }

#authentication .login-form-container .login-form, #password .login-form-container .login-form {
  background-color: #F6F6F6;
  padding: 20px;
  margin: 0;
  margin-bottom: 2px; }

#authentication .login-form-container .forgot-password a, #password .login-form-container .forgot-password a {
  color: #808A95; }

#authentication .login-form-container .btn-auth-form, #password .login-form-container .btn-auth-form {
  width: 100%;
  margin-top: 20px; }

#authentication .login-form-container hr, #password .login-form-container hr {
  width: calc(100% + 40px);
  margin-left: -20px;
  height: 2px;
  background: white;
  border: 0;
  margin-top: 20px; }

#authentication .login-form-container h3, #password .login-form-container h3 {
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem; }

#authentication .login-form-container .btn-noaccount, #password .login-form-container .btn-noaccount {
  background-color: #673B15;
  border-color: #673B15;
  width: 100%; }
  #authentication .login-form-container .btn-noaccount:hover, #password .login-form-container .btn-noaccount:hover {
    background-color: #522f11; }

.page-authentication #content {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: white;
  padding: 1rem;
  max-width: 640px;
  margin: 0 auto; }

/*** Addresses page ***/
.page-addresses .customer-addresses {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.page-addresses .address {
  background: #F6F6F6;
  flex: 0 0 100%;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .page-addresses .address {
      flex: 0 0 49%; } }
  .page-addresses .address .address-body {
    padding: 0.9375rem;
    margin-bottom: auto; }
    .page-addresses .address .address-body h4 {
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 700;
      font-size: 1.125rem;
      margin-bottom: 0.625rem;
      text-transform: uppercase;
      letter-spacing: 1px; }
    .page-addresses .address .address-body address {
      min-height: 5rem;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.375;
      margin: 0; }
  .page-addresses .address .address-footer {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.5rem; }
    .page-addresses .address .address-footer a {
      color: #808A95;
      display: inline-block;
      margin: 0 0.5rem; }
      .page-addresses .address .address-footer a:hover {
        text-decoration: underline; }
      .page-addresses .address .address-footer a i {
        font-size: 0.875rem;
        margin-right: 0.25rem; }

.page-addresses .addresses-footer {
  margin-top: 1.25rem; }
  .page-addresses .addresses-footer a {
    color: #2B3C4E; }
    .page-addresses .addresses-footer a i {
      font-size: 0.99995rem; }

/*** Order details page ***/
.page-order-detail {
  font-size: 0.875rem;
  color: #808A95; }
  .page-order-detail .box {
    margin-bottom: 1rem; }
  .page-order-detail h3 {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #2B3C4E;
    margin-bottom: 1rem; }
  .page-order-detail #order-infos ul {
    margin: 0; }
  .page-order-detail #order-history .history-lines .history-line {
    padding: 0.5rem 0;
    border-bottom: 1px solid #F6F6F6; }
    .page-order-detail #order-history .history-lines .history-line:last-child {
      border-bottom: 0; }
    .page-order-detail #order-history .history-lines .history-line .label {
      display: inline-block;
      margin: 0.25rem 0;
      padding: 0.25rem 0.375rem;
      color: white;
      border-radius: 3px; }
  .page-order-detail .addresses {
    margin: 0 -0.9375rem; }
    .page-order-detail .addresses h4 {
      font-size: 0.875rem;
      font-weight: 700; }
  .page-order-detail #order-products.return {
    margin-bottom: 1rem; }
    .page-order-detail #order-products.return th.head-checkbox {
      width: 30px; }
    .page-order-detail #order-products.return td {
      padding: 1.375rem 0.75rem; }
      .page-order-detail #order-products.return td.qty {
        min-width: 125px; }
        .page-order-detail #order-products.return td.qty .current {
          width: 30%;
          float: left;
          text-align: right;
          padding-right: 0.5rem; }
        .page-order-detail #order-products.return td.qty .select {
          width: 70%;
          float: left;
          margin: -0.625rem 0;
          padding-left: 0.25rem; }
          .page-order-detail #order-products.return td.qty .select select {
            text-align: center; }
  .page-order-detail .order-items {
    padding: 0 !important; }
    .page-order-detail .order-items .order-item {
      padding: 1rem 1rem 0;
      border-bottom: 1px solid #F6F6F6; }
      .page-order-detail .order-items .order-item .checkbox {
        width: 30px;
        float: left;
        padding: 0 0.9375rem; }
      .page-order-detail .order-items .order-item .content {
        width: calc(100% - 30px);
        float: left;
        padding: 0 0.9375rem; }
      .page-order-detail .order-items .order-item .desc {
        margin-bottom: 1rem; }
        .page-order-detail .order-items .order-item .desc .name {
          font-weight: bold; }
      .page-order-detail .order-items .order-item .qty {
        margin-bottom: 1rem; }
        .page-order-detail .order-items .order-item .qty .q {
          margin-bottom: 0.25rem; }
        .page-order-detail .order-items .order-item .qty .s {
          margin-bottom: 0.25rem; }
  .page-order-detail .messages .message {
    margin-top: 0.5rem;
    border-bottom: 1px solid #F6F6F6; }
    .page-order-detail .messages .message:last-child {
      border-bottom: 0; }
    .page-order-detail .messages .message > div {
      margin-bottom: 0.5rem; }
  .page-order-detail .customization {
    margin-top: 0.75rem; }

/*** Order return page ***/
#order-return-infos .thead-default th {
  color: #2B3C4E; }

#order-return-infos .customization {
  margin-top: 0.75rem; }

/*** My account landing page ***/
.page-my-account #content .links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .page-my-account #content .links a {
    text-align: center;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #808A95;
    padding: 0 0.9375rem;
    margin-bottom: 1.875rem; }
    .page-my-account #content .links a span.link-item {
      display: block;
      height: 100%;
      box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
      background: white;
      padding: 1rem; }
    .page-my-account #content .links a i {
      display: block;
      font-size: 2.6rem;
      width: 100%;
      color: #2B3C4E;
      padding-bottom: 3.4rem; }
    .page-my-account #content .links a:hover {
      color: #2B3C4E; }
      .page-my-account #content .links a:hover i {
        color: #F4793B; }

/*** History page ***/
#history .orders {
  margin: 0 -1rem; }
  #history .orders .order {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #F6F6F6; }
    #history .orders .order a h3 {
      color: #808A95; }
    #history .orders .order .label {
      display: inline-block;
      margin: 0.25rem 0;
      padding: 0.25rem 0.375rem;
      color: white;
      border-radius: 3px; }
    #history .orders .order:last-child {
      border-bottom: 0; }

.account-navigation.links a {
  display: inline-block;
  width: 100%;
  padding: 1.5rem;
  margin-top: 2px;
  background-color: #F6F6F6; }
  .account-navigation.links a .link-item {
    color: #2B3C4E;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1.125rem;
    font-weight: bold;
    display: flex;
    align-items: center; }
    .account-navigation.links a .link-item p {
      margin-bottom: 0; }
      .account-navigation.links a .link-item p span {
        color: #808A95;
        font-size: 0.875rem;
        font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 400;
        display: block; }
    .account-navigation.links a .link-item i {
      color: #F4793B;
      margin-right: 1rem; }
  .account-navigation.links a:hover {
    background-color: #DDDDDD;
    text-decoration: none; }

@media (max-width: 1279.98px) {
  .account-navigation.links {
    display: none; }
    #my-account .account-navigation.links {
      display: block; } }

.customer-breadcrumb li a {
  color: #808A95; }

.customer-breadcrumb li:last-child a {
  color: #2B3C4E; }

.account-title {
  font-size: 1.5rem; }
  @media (max-width: 1279.98px) {
    .account-title {
      display: none; } }

.history-table .table-row {
  display: flex;
  width: 100%;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #DDDDDD; }
  @media (max-width: 767.98px) {
    .history-table .table-row {
      flex-direction: column;
      border: 1px solid #DDDDDD;
      margin-bottom: 1rem; } }
  .history-table .table-row .table-col {
    float: left;
    display: flex; }
    .history-table .table-row .table-col label.sm-label {
      display: none; }
      @media (max-width: 767.98px) {
        .history-table .table-row .table-col label.sm-label {
          display: block;
          float: left;
          font-weight: bold;
          text-transform: uppercase;
          font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-size: 0.75rem;
          letter-spacing: 1px;
          width: 24%;
          margin-right: 5%; } }
    .history-table .table-row .table-col:nth-child(1) {
      flex: 0 0 20%; }
    .history-table .table-row .table-col:nth-child(2) {
      flex: 0 0 20%; }
    .history-table .table-row .table-col:nth-child(3) {
      flex: 0 0 17.5%; }
    .history-table .table-row .table-col:nth-child(4) {
      flex: 0 0 17.5%; }
    .history-table .table-row .table-col:nth-child(5) {
      flex: 0 0 20%; }
    .history-table .table-row .table-col:nth-child(6) {
      flex: 0 0 5%; }
    .history-table .table-row .table-col.btn-container {
      justify-content: center; }
      .history-table .table-row .table-col.btn-container a {
        width: 3rem;
        color: #FFF;
        height: 3rem;
        background-color: #F4793B;
        display: flex;
        flex-direction: column;
        transition: 300ms all ease-in-out;
        justify-content: center;
        text-align: center;
        align-items: center; }
        .history-table .table-row .table-col.btn-container a span {
          display: none; }
        .history-table .table-row .table-col.btn-container a:hover {
          text-decoration: none;
          background-color: #ef590d; }
        @media (max-width: 767.98px) {
          .history-table .table-row .table-col.btn-container a {
            width: 100%;
            font-weight: bold;
            text-transform: uppercase;
            font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            font-size: 0.75rem;
            letter-spacing: 1px; }
            .history-table .table-row .table-col.btn-container a i {
              display: none; }
            .history-table .table-row .table-col.btn-container a span {
              display: block; } }
    @media (max-width: 767.98px) {
      .history-table .table-row .table-col {
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 1rem; }
        .history-table .table-row .table-col:last-child {
          margin-bottom: 0; } }

.history-table.slips-table .table-col {
  flex: 0 0 25% !important; }

.history-table.discounts-table .table-col {
  flex: 0 0 14% !important; }
  .history-table.discounts-table .table-col:nth-child(7) {
    flex: 0 0 16% !important; }

.history-table.returns-table .table-col {
  flex: 0 0 20% !important; }

.history-table header .table-col {
  font-weight: bold;
  text-transform: uppercase;
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.75rem;
  letter-spacing: 1px; }

@media (max-width: 767.98px) {
  .history-table header {
    display: none !important; } }

.order-return-header {
  border-bottom: 0;
  background-color: #F6F6F6;
  flex-direction: column; }

p.order-status {
  display: flex;
  align-items: center;
  margin: 0; }
  p.order-status span {
    display: inline-block;
    float: left;
    width: 8px;
    height: 8px;
    border-radius: 50%; }
  p.order-status label {
    float: left;
    margin-left: 8px;
    width: calc(100% - 16px);
    margin-bottom: 0; }

.js-request-return-show {
  display: none !important; }
  .js-request-return-show.request-active {
    display: inline-block !important; }
    .js-request-return-show.request-active.-flex {
      display: flex !important; }

.js-request-return-hide.request-active {
  display: none !important; }

.history-detail-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  align-items: center;
  box-sizing: border-box;
  margin: 0 -1rem;
  width: auto; }
  @media (min-width: 576px) {
    .history-detail-row {
      flex-wrap: nowrap; } }
  .history-detail-row:after {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 0;
    content: '';
    display: block;
    border-bottom: 2px solid #F6F6F6; }
  .history-detail-row.history-detail-header {
    background-color: #F6F6F6;
    margin: 0; }
    .history-detail-row.history-detail-header:after {
      display: none; }
    .history-detail-row.history-detail-header .history-detail-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 1rem; }
      @media (min-width: 576px) {
        .history-detail-row.history-detail-header .history-detail-col {
          width: auto;
          flex: 0 0 auto;
          padding-bottom: 0; } }
      .history-detail-row.history-detail-header .history-detail-col:nth-child(1) {
        padding-right: 0; }
        @media (min-width: 576px) {
          .history-detail-row.history-detail-header .history-detail-col:nth-child(1) {
            padding-right: 1rem; } }
      .history-detail-row.history-detail-header .history-detail-col, .history-detail-row.history-detail-header .history-detail-col:nth-child(2) {
        width: 100%;
        flex: 0 0 100%; }
        @media (min-width: 576px) {
          .history-detail-row.history-detail-header .history-detail-col, .history-detail-row.history-detail-header .history-detail-col:nth-child(2) {
            width: auto;
            flex: 0 0 auto; } }
    .history-detail-row.history-detail-header .btn-return {
      background-color: #FFF;
      border-color: #FFF;
      color: #F4793B;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 100%; }
      @media (min-width: 576px) {
        .history-detail-row.history-detail-header .btn-return {
          flex: 0 0 auto;
          width: auto;
          margin-left: auto; } }
    .history-detail-row.history-detail-header p {
      margin: 0;
      color: #2B3C4E; }
    .history-detail-row.history-detail-header p.caption {
      font-weight: bold;
      text-transform: uppercase;
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-size: 0.75rem;
      letter-spacing: 1px; }
  .history-detail-row .history-detail-col {
    position: relative; }
    @media (min-width: 576px) {
      .history-detail-row .history-detail-col {
        padding-right: 1rem; } }
    .history-detail-row .history-detail-col .return-checkbox {
      position: absolute;
      padding: 0.5rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer; }
      .history-detail-row .history-detail-col .return-checkbox input {
        border: 1px solid #F4793B;
        background: #FFF;
        width: 1rem;
        height: 1rem; }
    .history-detail-row .history-detail-col .product-image {
      width: 4rem;
      height: auto; }
    .history-detail-row .history-detail-col .product-category {
      font-size: 0.8125rem;
      font-weight: 500;
      margin-bottom: 0; }
    .history-detail-row .history-detail-col .product-name {
      font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      font-size: 1rem;
      color: #2B3C4E;
      margin-bottom: 0; }
      @media (min-width: 576px) {
        .history-detail-row .history-detail-col .product-name {
          font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-weight: 700;
          font-size: 1.125rem; } }
    .history-detail-row .history-detail-col .form-control-select {
      -webkit-appearance: none;
      appearance: none; }
    .history-detail-row .history-detail-col:nth-child(1) {
      padding-right: 1rem; }
    .history-detail-row .history-detail-col:nth-child(2) {
      width: calc(100% - 4rem - 1rem); }
      @media (min-width: 576px) {
        .history-detail-row .history-detail-col:nth-child(2) {
          width: calc(100% - 4rem - 1rem - 20%); } }
    .history-detail-row .history-detail-col.attributes-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      flex: 0 0 100%;
      padding-top: 1rem; }
      @media (min-width: 576px) {
        .history-detail-row .history-detail-col.attributes-col {
          width: 20%;
          flex: 0 0 20%;
          padding-top: 0;
          padding-right: 0; } }
      .history-detail-row .history-detail-col.attributes-col .product-pieces {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #2B3C4E;
        font-size: 0.875rem; }
        @media (min-width: 576px) {
          .history-detail-row .history-detail-col.attributes-col .product-pieces {
            padding-left: 1rem;
            border-left: 1px solid #DDDDDD; } }
        .history-detail-row .history-detail-col.attributes-col .product-pieces img {
          margin-right: 0.5rem; }
        .history-detail-row .history-detail-col.attributes-col .product-pieces strong {
          font-weight: 500; }

.summary-history-detail div:first-child p:last-child {
  margin-bottom: 0; }

@media (max-width: 1279.98px) {
  .summary-history-detail {
    display: flex;
    flex-direction: column; }
    .summary-history-detail > div:nth-child(1) {
      order: 3; }
    .summary-history-detail > div:nth-child(2) {
      order: 4; }
    .summary-history-detail > div:nth-child(3) {
      order: 1; }
    .summary-history-detail > div:nth-child(4) {
      order: 2; }
    .summary-history-detail > div:nth-child(5) {
      order: 5; } }

.summary-history-detail .summary-history-detail-row {
  background-color: #F6F6F6;
  box-shadow: none;
  display: block;
  width: 100%;
  margin-bottom: 2px;
  padding: 20px;
  color: #2B3C4E; }
  @media (max-width: 1279.98px) {
    .summary-history-detail .summary-history-detail-row {
      background-color: transparent;
      border-bottom: 2px solid #F6F6F6;
      padding-left: 0;
      padding-right: 0; }
      .summary-history-detail .summary-history-detail-row:last-child {
        border-bottom: 0; } }

.summary-history-detail address {
  margin-bottom: 0; }

.summary-history-detail p.caption {
  font-weight: bold;
  text-transform: uppercase;
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 0.75rem;
  letter-spacing: 1px; }

.summary-history-detail p.subcaption {
  font-weight: bold;
  font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.125rem; }

.summary-history-detail .subtotals div {
  display: inline-block;
  width: 100%; }
  .summary-history-detail .subtotals div p {
    float: left;
    margin: 0;
    line-height: 1rem; }
    .summary-history-detail .subtotals div p:last-child {
      margin-left: 2rem;
      float: right; }
  .summary-history-detail .subtotals div:first-child {
    margin-bottom: 1.5rem; }

.summary-history-detail .total div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .summary-history-detail .total div p {
    font-size: 1.5rem;
    float: right;
    font-weight: bold;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5rem;
    margin-bottom: 0; }
    .summary-history-detail .total div p span {
      font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #808A95;
      font-weight: 400;
      font-size: 0.75rem; }
    .summary-history-detail .total div p.total {
      float: left; }

.summary-history-detail .btn-primary, .summary-history-detail .homepage-row .custom-text a, .homepage-row .custom-text .summary-history-detail a {
  width: 100%;
  margin-top: 3rem; }
  @media (max-width: 1279.98px) {
    .summary-history-detail .btn-primary, .summary-history-detail .homepage-row .custom-text a, .homepage-row .custom-text .summary-history-detail a {
      margin-top: 1rem;
      margin-bottom: 2.5rem; } }

/*** FOOTER ***/
.page-footer .account-link {
  margin-right: 1rem; }
  .page-footer .account-link i {
    font-size: 0.875rem; }
  .page-footer .account-link span {
    font-size: 0.74996rem;
    vertical-align: middle; }

.login-form {
  margin-top: 15px; }

#order-return-form .product-image {
  margin-left: 2rem; }

/*
.forgotten-password {
  padding: 4px;

  .form-fields {

    .center-email-fields {
      display: flex;
      justify-content: center;

      @media (max-width: 767px) {
        flex-direction: column;

        button {
          margin: 10px;
          width: calc(100% - 20px);
        }
      }

      button {
        height: 38px;
      }
    }

    .email {
      padding-left: 0;
      padding-right: 0;
      width: 430px;

      @media (max-width: 767px) {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }

      input {
        height: 38px;
      }
    }
  }
}

.send-renew-password-link {
  padding-left: 10px;
  padding-right: 10px;
}

*/
.renew-password {
  margin-left: 10px; }
  .renew-password .email {
    padding-bottom: 30px; }
  .renew-password [type=submit] {
    margin-left: 50px; }

.carousel-home {
  width: 100vw;
  margin-left: calc(50% - 50vw); }
  .carousel-home .direction {
    z-index: auto; }
  .carousel-home .carousel-item {
    height: 100%; }
    .carousel-home .carousel-item .cover {
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      .carousel-home .carousel-item .cover .cover-inner {
        position: relative;
        max-width: 32rem;
        margin: 0 auto;
        padding: 5rem 0 12rem;
        text-align: center; }
        .carousel-home .carousel-item .cover .cover-inner .cover-title, .carousel-home .carousel-item .cover .cover-inner .cover-description {
          text-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25); }
        .carousel-home .carousel-item .cover .cover-inner .cover-title {
          text-align: center;
          font-size: 3rem;
          color: #FFF;
          font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          margin-bottom: 1rem; }
        .carousel-home .carousel-item .cover .cover-inner .cover-description {
          font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-size: 1.125rem;
          line-height: 2;
          color: #FFF;
          margin-bottom: 2rem; }
          .carousel-home .carousel-item .cover .cover-inner .cover-description h1, .carousel-home .carousel-item .cover .cover-inner .cover-description h2, .carousel-home .carousel-item .cover .cover-inner .cover-description h3, .carousel-home .carousel-item .cover .cover-inner .cover-description h4, .carousel-home .carousel-item .cover .cover-inner .cover-description h5, .carousel-home .carousel-item .cover .cover-inner .cover-description h6 {
            font-size: 1.25rem;
            line-height: 1.0833;
            font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            font-weight: 700;
            margin-bottom: 0.41em;
            color: inherit; }
            .carousel-home .carousel-item .cover .cover-inner .cover-description h1 *, .carousel-home .carousel-item .cover .cover-inner .cover-description h2 *, .carousel-home .carousel-item .cover .cover-inner .cover-description h3 *, .carousel-home .carousel-item .cover .cover-inner .cover-description h4 *, .carousel-home .carousel-item .cover .cover-inner .cover-description h5 *, .carousel-home .carousel-item .cover .cover-inner .cover-description h6 * {
              font-weight: inherit !important;
              font-size: inherit !important;
              font-family: inherit !important;
              color: inherit !important; }
          .carousel-home .carousel-item .cover .cover-inner .cover-description h1 {
            font-size: 2rem; }
          .carousel-home .carousel-item .cover .cover-inner .cover-description h2 {
            font-size: 1.5rem; }
          .carousel-home .carousel-item .cover .cover-inner .cover-description p, .carousel-home .carousel-item .cover .cover-inner .cover-description p * {
            font-weight: inherit !important;
            font-size: inherit !important;
            font-family: inherit !important;
            color: inherit !important; }
        .carousel-home .carousel-item .cover .cover-inner .btn, .carousel-home .carousel-item .cover .cover-inner .homepage-row .custom-text a, .homepage-row .custom-text .carousel-home .carousel-item .cover .cover-inner a {
          min-width: 10rem; }

#products .products, .featured-products .products, .product-accessories .products, .product-miniature .products {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

#products .product-thumbnail, .featured-products .product-thumbnail, .product-accessories .product-thumbnail, .product-miniature .product-thumbnail {
  display: block;
  text-align: center; }
  #products .product-thumbnail img, .featured-products .product-thumbnail img, .product-accessories .product-thumbnail img, .product-miniature .product-thumbnail img {
    width: 100%;
    max-height: 11rem;
    object-fit: contain; }

#products .product-purchase, .featured-products .product-purchase, .product-accessories .product-purchase, .product-miniature .product-purchase {
  display: flex;
  justify-content: space-between; }

#products .product-category, .featured-products .product-category, .product-accessories .product-category, .product-miniature .product-category {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #808A95;
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 0; }

#products .product-title, .featured-products .product-title, .product-accessories .product-title, .product-miniature .product-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  #products .product-title a, .featured-products .product-title a, .product-accessories .product-title a, .product-miniature .product-title a {
    color: #2B3C4E;
    text-transform: capitalize;
    font-size: 1.125rem;
    text-decoration: none;
    text-align: center;
    font-weight: bold; }

#products .thumbnail-container, .featured-products .thumbnail-container, .product-accessories .thumbnail-container, .product-miniature .thumbnail-container {
  position: relative;
  height: 100%;
  width: 100%; }
  #products .thumbnail-container:hover .highlighted-informations, #products .thumbnail-container:focus .highlighted-informations, .featured-products .thumbnail-container:hover .highlighted-informations, .featured-products .thumbnail-container:focus .highlighted-informations, .product-accessories .thumbnail-container:hover .highlighted-informations, .product-accessories .thumbnail-container:focus .highlighted-informations, .product-miniature .thumbnail-container:hover .highlighted-informations, .product-miniature .thumbnail-container:focus .highlighted-informations {
    bottom: 5.5rem; }
    #products .thumbnail-container:hover .highlighted-informations::after, #products .thumbnail-container:focus .highlighted-informations::after, .featured-products .thumbnail-container:hover .highlighted-informations::after, .featured-products .thumbnail-container:focus .highlighted-informations::after, .product-accessories .thumbnail-container:hover .highlighted-informations::after, .product-accessories .thumbnail-container:focus .highlighted-informations::after, .product-miniature .thumbnail-container:hover .highlighted-informations::after, .product-miniature .thumbnail-container:focus .highlighted-informations::after {
      opacity: 1; }
    #products .thumbnail-container:hover .highlighted-informations.no-variants, #products .thumbnail-container:focus .highlighted-informations.no-variants, .featured-products .thumbnail-container:hover .highlighted-informations.no-variants, .featured-products .thumbnail-container:focus .highlighted-informations.no-variants, .product-accessories .thumbnail-container:hover .highlighted-informations.no-variants, .product-accessories .thumbnail-container:focus .highlighted-informations.no-variants, .product-miniature .thumbnail-container:hover .highlighted-informations.no-variants, .product-miniature .thumbnail-container:focus .highlighted-informations.no-variants {
      bottom: 4.2rem; }
  #products .thumbnail-container:hover .product-description:after, #products .thumbnail-container:focus .product-description:after, .featured-products .thumbnail-container:hover .product-description:after, .featured-products .thumbnail-container:focus .product-description:after, .product-accessories .thumbnail-container:hover .product-description:after, .product-accessories .thumbnail-container:focus .product-description:after, .product-miniature .thumbnail-container:hover .product-description:after, .product-miniature .thumbnail-container:focus .product-description:after {
    content: "";
    border-top: #DDDDDD 1px solid;
    position: absolute;
    width: 80%;
    top: 0;
    left: 10%;
    opacity: .25; }

#products .product-price-and-shipping, .featured-products .product-price-and-shipping, .product-accessories .product-price-and-shipping, .product-miniature .product-price-and-shipping {
  color: #2B3C4E;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  justify-content: center; }
  #products .product-price-and-shipping .product-price-row, .featured-products .product-price-and-shipping .product-price-row, .product-accessories .product-price-and-shipping .product-price-row, .product-miniature .product-price-and-shipping .product-price-row {
    font-size: 1rem; }
    #products .product-price-and-shipping .product-price-row:first-child, .featured-products .product-price-and-shipping .product-price-row:first-child, .product-accessories .product-price-and-shipping .product-price-row:first-child, .product-miniature .product-price-and-shipping .product-price-row:first-child {
      font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      font-weight: 200;
      text-align: left; }
    #products .product-price-and-shipping .product-price-row:last-child span.price, .featured-products .product-price-and-shipping .product-price-row:last-child span.price, .product-accessories .product-price-and-shipping .product-price-row:last-child span.price, .product-miniature .product-price-and-shipping .product-price-row:last-child span.price {
      font-size: 1.125em;
      color: #2B3C4E;
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; }
    #products .product-price-and-shipping .product-price-row:last-child span.regular-price, .featured-products .product-price-and-shipping .product-price-row:last-child span.regular-price, .product-accessories .product-price-and-shipping .product-price-row:last-child span.regular-price, .product-miniature .product-price-and-shipping .product-price-row:last-child span.regular-price {
      color: #808A95;
      font-size: 1em;
      margin-left: 0.5rem;
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 400; }

#products .variant-links, .featured-products .variant-links, .product-accessories .variant-links, .product-miniature .variant-links {
  position: relative;
  text-align: center;
  width: 100%;
  top: -0.25em;
  padding-top: 0.1875rem;
  min-height: 2.5rem; }

#products .highlighted-informations, .featured-products .highlighted-informations, .product-accessories .highlighted-informations, .product-miniature .highlighted-informations {
  position: absolute;
  bottom: 1.25rem;
  padding-top: 0.625rem;
  z-index: 0;
  text-align: center;
  width: 257px;
  height: 3.125rem;
  box-shadow: 0 -5px 10px -5px rgba(0, 0, 0, 0.2);
  transition: bottom .3s; }
  #products .highlighted-informations .quick-view, .featured-products .highlighted-informations .quick-view, .product-accessories .highlighted-informations .quick-view, .product-miniature .highlighted-informations .quick-view {
    color: #808A95;
    font-size: 0.875rem; }
    #products .highlighted-informations .quick-view:hover, .featured-products .highlighted-informations .quick-view:hover, .product-accessories .highlighted-informations .quick-view:hover, .product-miniature .highlighted-informations .quick-view:hover {
      color: #F4793B; }

#products .product-description, .featured-products .product-description, .product-accessories .product-description, .product-miniature .product-description {
  margin-top: 1rem; }

#products .product-miniature, .featured-products .product-miniature, .product-accessories .product-miniature, .product-miniature .product-miniature {
  position: relative;
  margin: 0 20px 40px;
  width: 100%;
  padding: 1rem;
  height: 20.625rem;
  background-color: #F6F6F6; }
  @media (min-width: 768px) {
    #products .product-miniature, .featured-products .product-miniature, .product-accessories .product-miniature, .product-miniature .product-miniature {
      max-width: 19.625rem; } }
  #products .product-miniature .product-flags, .featured-products .product-miniature .product-flags, .product-accessories .product-miniature .product-flags, .product-miniature .product-miniature .product-flags {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2; }
  #products .product-miniature .product-flag, .featured-products .product-miniature .product-flag, .product-accessories .product-miniature .product-flag, .product-miniature .product-miniature .product-flag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.25rem;
    padding: 0.25rem 0.5rem;
    color: white;
    background: #2B3C4E;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    box-shadow: none !important; }
    #products .product-miniature .product-flag.discount, #products .product-miniature .product-flag.discount-percentage, #products .product-miniature .product-flag.discount-amount, .featured-products .product-miniature .product-flag.discount, .featured-products .product-miniature .product-flag.discount-percentage, .featured-products .product-miniature .product-flag.discount-amount, .product-accessories .product-miniature .product-flag.discount, .product-accessories .product-miniature .product-flag.discount-percentage, .product-accessories .product-miniature .product-flag.discount-amount, .product-miniature .product-miniature .product-flag.discount, .product-miniature .product-miniature .product-flag.discount-percentage, .product-miniature .product-miniature .product-flag.discount-amount {
      background-color: #1DA1F2; }
    #products .product-miniature .product-flag.new, .featured-products .product-miniature .product-flag.new, .product-accessories .product-miniature .product-flag.new, .product-miniature .product-miniature .product-flag.new {
      background-color: #E30613; }
    #products .product-miniature .product-flag.on-sale, .featured-products .product-miniature .product-flag.on-sale, .product-accessories .product-miniature .product-flag.on-sale, .product-miniature .product-miniature .product-flag.on-sale {
      background: #1DA1F2;
      width: 100%;
      text-align: center;
      left: 0;
      top: 0; }
  #products .product-miniature .qty_container, .featured-products .product-miniature .qty_container, .product-accessories .product-miniature .qty_container, .product-miniature .product-miniature .qty_container {
    width: 7.5rem;
    height: 3rem; }
    #products .product-miniature .qty_container .change_qty, .featured-products .product-miniature .qty_container .change_qty, .product-accessories .product-miniature .qty_container .change_qty, .product-miniature .product-miniature .qty_container .change_qty {
      width: 1.5rem;
      display: flex;
      flex-direction: column;
      float: left; }
      #products .product-miniature .qty_container .change_qty span, .featured-products .product-miniature .qty_container .change_qty span, .product-accessories .product-miniature .qty_container .change_qty span, .product-miniature .product-miniature .qty_container .change_qty span {
        width: 100%;
        background-color: #FFF;
        height: 1.5rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        color: #F4793B;
        font-size: 1.2rem; }
        #products .product-miniature .qty_container .change_qty span:first-child, .featured-products .product-miniature .qty_container .change_qty span:first-child, .product-accessories .product-miniature .qty_container .change_qty span:first-child, .product-miniature .product-miniature .qty_container .change_qty span:first-child {
          border-bottom: 1px solid #DDDDDD;
          color: #F4793B; }
    #products .product-miniature .qty_container input, .featured-products .product-miniature .qty_container input, .product-accessories .product-miniature .qty_container input, .product-miniature .product-miniature .qty_container input {
      width: 3rem;
      height: 3rem;
      border-radius: 0;
      border: 0;
      border-left: 1px solid #DDDDDD;
      float: left;
      text-align: center; }
    #products .product-miniature .qty_container button, .featured-products .product-miniature .qty_container button, .product-accessories .product-miniature .qty_container button, .product-miniature .product-miniature .qty_container button {
      width: 3rem;
      float: left;
      display: flex;
      justify-content: center; }
      #products .product-miniature .qty_container button i, .featured-products .product-miniature .qty_container button i, .product-accessories .product-miniature .qty_container button i, .product-miniature .product-miniature .qty_container button i {
        margin: 0; }

#products .comments_note, .featured-products .comments_note, .product-accessories .comments_note, .product-miniature .comments_note {
  text-align: center;
  color: #808A95; }

#products .regular-price, .featured-products .regular-price, .product-accessories .regular-price, .product-miniature .regular-price {
  color: #808A95;
  text-decoration: line-through;
  font-size: 0.74996rem; }

#products .count, .featured-products .count, .product-accessories .count, .product-miniature .count {
  color: #808A95;
  font-weight: 700;
  position: relative;
  bottom: 0.5rem; }

#products .all-product-link, .featured-products .all-product-link, .product-accessories .all-product-link, .product-miniature .all-product-link {
  clear: both;
  color: #808A95;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.featured-products-carousel, .promotion-products-carousel {
  margin-bottom: 2rem; }
  @media (min-width: 1280px) {
    .featured-products-carousel, .promotion-products-carousel {
      margin-bottom: 4rem; } }
  .featured-products-carousel .products-section-title, .promotion-products-carousel .products-section-title {
    font-size: 1.5rem;
    margin-bottom: 1rem; }
    @media (min-width: 1280px) {
      .featured-products-carousel .products-section-title, .promotion-products-carousel .products-section-title {
        font-size: 1.75rem;
        margin-bottom: 2.5rem; } }
  .featured-products-carousel .products, .promotion-products-carousel .products {
    display: block;
    margin-bottom: 1.5rem; }
  .featured-products-carousel .product-miniature, .promotion-products-carousel .product-miniature {
    margin: 0 auto;
    width: 100%; }
  .featured-products-carousel .carousel-inner, .promotion-products-carousel .carousel-inner {
    margin: 0 -20px;
    width: auto; }
  .featured-products-carousel .carousel-item, .promotion-products-carousel .carousel-item {
    padding: 0 20px; }
  .featured-products-carousel .btn-outline-light, .promotion-products-carousel .btn-outline-light {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border-color: #DDDDDD;
    color: #2B3C4E;
    padding: 0; }
    .featured-products-carousel .btn-outline-light span, .promotion-products-carousel .btn-outline-light span {
      margin: 0; }

.featured-products-carousel .product-miniature {
  margin: 0 auto;
  width: 100%; }
  @media (min-width: 1280px) {
    .featured-products-carousel .product-miniature {
      max-width: 22.125rem; } }

@media (min-width: 1280px) {
  .featured-products-carousel .carousel-item {
    width: 50%;
    margin-right: 0;
    /* left or forward direction */
    /* farthest right hidden item must be also positioned for animations */
    /* right or prev direction */ }
    .featured-products-carousel .carousel-item.active + .carousel-item {
      display: block; }
    .featured-products-carousel .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .featured-products-carousel .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
      transition: none; }
    .featured-products-carousel .carousel-item.carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0); } }
  @media (min-width: 1280px) and (max-width: 1439.98px) {
    .featured-products-carousel .carousel-item {
      /* Show 3rd slide on sm */ }
      .featured-products-carousel .carousel-item.active.carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -50%;
        /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible; } }

@media (min-width: 1280px) {
    .featured-products-carousel .carousel-item.active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .featured-products-carousel .carousel-item.carousel-item-next.carousel-item-left + .carousel-item,
    .featured-products-carousel .carousel-item.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible; }
    .featured-products-carousel .carousel-item.carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      visibility: visible; }
    .featured-products-carousel .carousel-item.active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .featured-products-carousel .carousel-item.carousel-item-prev.carousel-item-right + .carousel-item,
    .featured-products-carousel .carousel-item.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block; } }

@media (min-width: 1440px) {
  .featured-products-carousel .carousel-item {
    width: 33.3333%;
    /* Show 4th slide on md */
    /* left or forward direction */
    /* right or prev direction */ }
    .featured-products-carousel .carousel-item.active + .carousel-item + .carousel-item {
      display: block; }
    .featured-products-carousel .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
      transition: none; }
    .featured-products-carousel .carousel-item.carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0); }
    .featured-products-carousel .carousel-item.active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -33.3333%;
      /*change this with javascript in the future*/
      z-index: -1;
      display: block;
      visibility: visible; }
    .featured-products-carousel .carousel-item.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible; }
    .featured-products-carousel .carousel-item.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block; } }

.promotion-products-carousel .product-miniature {
  margin: 0 auto;
  width: 100%; }
  @media (min-width: 768px) {
    .promotion-products-carousel .product-miniature {
      max-width: 22.125rem; } }

@media (min-width: 768px) {
  .promotion-products-carousel .carousel-item {
    width: 50%;
    margin-right: 0;
    /* left or forward direction */
    /* farthest right hidden item must be also positioned for animations */
    /* right or prev direction */ }
    .promotion-products-carousel .carousel-item.active + .carousel-item {
      display: block; }
    .promotion-products-carousel .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .promotion-products-carousel .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
      transition: none; }
    .promotion-products-carousel .carousel-item.carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0); } }
  @media (min-width: 768px) and (max-width: 1279.98px) {
    .promotion-products-carousel .carousel-item {
      /* Show 3rd slide on sm */ }
      .promotion-products-carousel .carousel-item.active.carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -50%;
        /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible; } }

@media (min-width: 768px) {
    .promotion-products-carousel .carousel-item.active.carousel-item-left + .carousel-item-next.carousel-item-left,
    .promotion-products-carousel .carousel-item.carousel-item-next.carousel-item-left + .carousel-item,
    .promotion-products-carousel .carousel-item.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible; }
    .promotion-products-carousel .carousel-item.carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      visibility: visible; }
    .promotion-products-carousel .carousel-item.active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    .promotion-products-carousel .carousel-item.carousel-item-prev.carousel-item-right + .carousel-item,
    .promotion-products-carousel .carousel-item.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block; } }

@media (min-width: 1280px) {
  .promotion-products-carousel .carousel-item {
    width: 33.3333%;
    /* left or forward direction */
    /* right or prev direction */ }
    .promotion-products-carousel .carousel-item.active + .carousel-item + .carousel-item {
      display: block; }
    .promotion-products-carousel .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
      transition: none; }
    .promotion-products-carousel .carousel-item.carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0); } }
  @media (min-width: 1280px) and (max-width: 1439.98px) {
    .promotion-products-carousel .carousel-item {
      /* Show 4th slide on md */ }
      .promotion-products-carousel .carousel-item.active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
        position: absolute;
        top: 0;
        right: -33.3333%;
        /*change this with javascript in the future*/
        z-index: -1;
        display: block;
        visibility: visible; } }

@media (min-width: 1280px) {
    .promotion-products-carousel .carousel-item.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible; }
    .promotion-products-carousel .carousel-item.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block; } }

@media (min-width: 1440px) {
  .promotion-products-carousel .carousel-item {
    width: 25%;
    /* Show 5th slide on lg */
    /* left or forward direction */
    /* right or prev direction //t - previous slide direction last item animation fix */ }
    .promotion-products-carousel .carousel-item.active + .carousel-item + .carousel-item + .carousel-item {
      display: block; }
    .promotion-products-carousel .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
      transition: none; }
    .promotion-products-carousel .carousel-item.active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -25%;
      /*change this with javascript in the future*/
      z-index: -1;
      display: block;
      visibility: visible; }
    .promotion-products-carousel .carousel-item.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible; }
    .promotion-products-carousel .carousel-item.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block; } }

#custom-text {
  background: white;
  border-radius: 2px;
  margin-bottom: 1.5rem;
  padding: 3.125rem 3.125rem;
  text-align: center; }
  #custom-text h2 {
    text-transform: uppercase;
    color: #2B3C4E;
    font-size: 1.563rem;
    font-weight: 700; }
  #custom-text p {
    color: #2B3C4E;
    font-weight: 400;
    font-size: 1.1em; }
    #custom-text p .dark {
      color: #808A95;
      font-weight: 400; }

.page-content.page-cms {
  background: white;
  padding: 1.25rem;
  text-align: justify; }
  .page-content.page-cms .cms-box img {
    max-width: 100%; }

@media (max-width: 1279.98px) {
  #block-cmsinfo {
    padding: 0.875rem 1rem; } }

#category #products .products {
  justify-content: space-around; }

.block-category {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 1rem 0 2rem;
  min-height: 10rem;
  background-color: #808A95;
  background-size: cover;
  background-position: center; }
  @media (min-width: 768px) {
    .block-category {
      min-height: 13.75rem; } }
  @media (min-width: 1280px) {
    .block-category {
      margin: 4.5rem 0;
      padding: 2rem 1rem; } }
  .block-category .category-title,
  .block-category .category-description,
  .block-category .category-description * {
    color: #FFF !important;
    text-align: center !important;
    font-size: 1rem !important;
    line-height: 1.4 !important;
    text-shadow: 2px 2px 1px #333;
    font-weight: 400 !important;
    margin: 0; }
    @media (min-width: 768px) {
      .block-category .category-title,
      .block-category .category-description,
      .block-category .category-description * {
        font-size: 1.5rem !important; } }
    @media (min-width: 1280px) {
      .block-category .category-title,
      .block-category .category-description,
      .block-category .category-description * {
        font-size: 2rem !important; } }
  @media (min-width: 1280px) {
    .block-category .category-description {
      max-width: 80%;
      margin: 0 auto; } }

.products-selection .sort-by-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .products-selection .sort-by-row:after {
    content: '';
    height: 1px;
    width: 100%;
    flex: 0 0 100%;
    background-color: #DDDDDD;
    margin-bottom: 1rem; }
    @media (min-width: 1280px) {
      .products-selection .sort-by-row:after {
        display: none; } }
  .products-selection .sort-by-row .btn, .products-selection .sort-by-row .homepage-row .custom-text a, .homepage-row .custom-text .products-selection .sort-by-row a {
    padding-left: 0;
    padding-right: 0; }

.products-selection .sort-by {
  white-space: normal;
  word-break: break-word;
  text-align: right;
  margin-right: 1rem;
  margin-left: auto; }

.products-selection .total-products {
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #808A95;
  display: flex;
  align-items: center; }

.products-selection .filter-button {
  margin-left: auto; }
  .products-selection .filter-button .material-icons {
    font-size: 18px; }

.products-sort-order .select-list {
  display: block;
  color: #2B3C4E;
  padding: 0.75rem 0.875rem; }
  .products-sort-order .select-list:hover {
    background: #F4793B;
    color: white;
    text-decoration: none; }

.products-sort-order .dropdown-menu {
  left: auto;
  width: 16.88rem;
  background: #F6F6F6;
  border: none;
  border-radius: 0;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin: 0; }

.advertising-block {
  margin-bottom: 1.563rem; }
  .advertising-block img {
    width: 100%; }

#product #content {
  position: relative;
  margin: 0 auto; }

.product-price {
  color: #F4793B;
  display: inline-block; }

#product-description-short {
  color: #2B3C4E; }

.product-information {
  font-size: 0.99995rem;
  color: #2B3C4E; }
  .product-information .manufacturer-logo {
    height: 35px; }
  .product-information .product-description img {
    max-width: 100%;
    height: auto; }

.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem; }

.input-container {
  position: relative; }

.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%; }

.input-color:checked + span,
.input-color:hover + span,
.input-radio:checked + span,
.input-radio:hover + span {
  border: 2px solid #2B3C4E; }

.radio-label {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: white;
  display: inline-block;
  padding: 0.125rem 0.75rem;
  font-weight: 600;
  border: 2px solid white; }

@media (max-width: 1279.98px) {
  .product-actions {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 5;
    box-shadow: -2px -2px 3px #ddd; } }

.product-actions .control-label {
  width: 100%;
  margin-bottom: 0.375rem;
  display: block; }

.product-actions .add-to-cart {
  height: 2.75rem;
  line-height: inherit;
  padding-top: 0.625rem; }
  .product-actions .add-to-cart .material-icons {
    line-height: inherit; }

.product-quantity {
  display: flex;
  justify-content: space-between; }
  .product-quantity .qty, .product-quantity .add {
    float: left;
    display: inline-flex;
    margin-bottom: 0.5rem; }
  .product-quantity .qty {
    margin-right: 0.4rem; }
  .product-quantity #quantity_wanted {
    color: #2B3C4E;
    background-color: white;
    height: 2.75rem;
    padding: 0.175rem 0.5rem;
    width: 3rem; }
  .product-quantity .input-group-btn-vertical {
    width: auto; }
    .product-quantity .input-group-btn-vertical .btn, .product-quantity .input-group-btn-vertical .homepage-row .custom-text a, .homepage-row .custom-text .product-quantity .input-group-btn-vertical a {
      padding: 0.5rem 0.6875rem; }
      .product-quantity .input-group-btn-vertical .btn i, .product-quantity .input-group-btn-vertical .homepage-row .custom-text a i, .homepage-row .custom-text .product-quantity .input-group-btn-vertical a i {
        font-size: 1rem;
        top: 0.125rem;
        left: 0.1875rem; }
  .product-quantity .btn-touchspin {
    height: 1.438rem; }

.product-discounts {
  margin-bottom: 1.5rem; }
  .product-discounts > .product-discounts-title {
    font-weight: normal;
    font-size: 0.74996rem; }
  .product-discounts > .table-product-discounts thead tr th {
    width: 33%;
    padding: 0.75rem 0.875rem;
    background: white;
    border: 0.3125rem #F6F6F6 solid;
    text-align: center; }
  .product-discounts > .table-product-discounts tbody tr {
    background: #F6F6F6; }
    .product-discounts > .table-product-discounts tbody tr:nth-of-type(even) {
      background: white; }
    .product-discounts > .table-product-discounts tbody tr td {
      padding: 0.75rem 0.875rem;
      text-align: center;
      border: 0.3125rem #F6F6F6 solid; }

.product-details-box h1 {
  font-size: 2rem; }

.product-details-box h3 {
  font-size: 1.3rem; }

.product-details-box .product-information {
  background-color: #F6F6F6;
  padding: 20px; }
  .product-details-box .product-information p, .product-details-box .product-information span {
    color: #808A95 !important;
    font-size: 0.875rem !important; }
  .product-details-box .product-information .product-flags {
    position: static;
    margin-bottom: 20px; }
    .product-details-box .product-information .product-flags .product-flag {
      color: #FFF;
      text-transform: uppercase;
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      background: #2B3C4E;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.25rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      font-weight: 700; }
      .product-details-box .product-information .product-flags .product-flag.discount, .product-details-box .product-information .product-flags .product-flag.discount-percentage, .product-details-box .product-information .product-flags .product-flag.discount-amount {
        background-color: #1DA1F2; }
      .product-details-box .product-information .product-flags .product-flag.new {
        background-color: #E30613; }
      .product-details-box .product-information .product-flags .product-flag.on-sale {
        background: #1DA1F2;
        width: 100%;
        text-align: center;
        left: 0;
        top: 0; }

.product-details-box .product-variants .product-variants-item {
  margin-top: 2px;
  margin-bottom: 2px; }
  @media (max-width: 1279.98px) {
    .product-details-box .product-variants .product-variants-item {
      margin: 0; } }
  .product-details-box .product-variants .product-variants-item ul {
    display: flex;
    margin-bottom: 0; }
    .product-details-box .product-variants .product-variants-item ul li {
      width: 50%;
      background-color: #F6F6F6;
      height: 3.5rem;
      line-height: 3.5rem; }
      .product-details-box .product-variants .product-variants-item ul li:first-child {
        margin-right: 1px;
        margin-left: 0; }
        .product-details-box .product-variants .product-variants-item ul li:first-child label, .product-details-box .product-variants .product-variants-item ul li:first-child span {
          color: #2B3C4E; }
      .product-details-box .product-variants .product-variants-item ul li:last-child {
        margin-right: 0;
        margin-left: 1px; }
      .product-details-box .product-variants .product-variants-item ul li label, .product-details-box .product-variants .product-variants-item ul li span {
        width: 100%;
        text-align: center;
        box-shadow: none;
        font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        background-color: transparent;
        font-size: 0.875rem;
        font-weight: 400;
        border: 0;
        color: #808A95; }
        .product-details-box .product-variants .product-variants-item ul li label i, .product-details-box .product-variants .product-variants-item ul li span i {
          margin-right: 10px; }
        .product-details-box .product-variants .product-variants-item ul li label img, .product-details-box .product-variants .product-variants-item ul li span img {
          margin-top: -5px;
          margin-right: 10px; }

.product-details-box .product-actions.active .product-variants .product-variants-item ul li:first-child label, .product-details-box .product-actions.active .product-variants .product-variants-item ul li:first-child span {
  color: #808A95; }

.product-details-box .product-actions.active .product-variants .product-variants-item ul li:last-child label, .product-details-box .product-actions.active .product-variants .product-variants-item ul li:last-child span {
  color: #2B3C4E; }

.product-details-box .product-prices {
  margin-top: 0.875rem; }
  .product-details-box .product-prices div {
    margin-bottom: 0.625rem; }
  .product-details-box .product-prices .tax-shipping-delivery-label {
    font-size: 0.8125rem;
    color: #808A95; }
    .product-details-box .product-prices .tax-shipping-delivery-label .delivery-information {
      padding: 0 0 0 2px; }
      .product-details-box .product-prices .tax-shipping-delivery-label .delivery-information::before {
        content: "-";
        padding: 0 2px 0 0; }

.product-details-box .product-add-to-cart {
  background-color: #F6F6F6; }
  .product-details-box .product-add-to-cart .product-minimal-quantity {
    margin: 0; }
  .product-details-box .product-add-to-cart .product-quantity {
    padding: 20px;
    padding-bottom: 0; }
    .product-details-box .product-add-to-cart .product-quantity .product-prices {
      flex: 0 0 50%;
      margin-top: 0;
      max-width: 50%;
      text-align: right; }
      .product-details-box .product-add-to-cart .product-quantity .product-prices .current-price {
        color: #2B3C4E;
        font-size: 2rem;
        font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; }
    .product-details-box .product-add-to-cart .product-quantity .qty {
      box-shadow: none;
      flex: 0 0 50%;
      max-width: 50%;
      margin-right: 0; }
      .product-details-box .product-add-to-cart .product-quantity .qty .bootstrap-touchspin {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        width: 100%;
        justify-content: space-between; }
        .product-details-box .product-add-to-cart .product-quantity .qty .bootstrap-touchspin #quantity_wanted {
          height: 2.5rem;
          border: 0;
          width: 4rem;
          max-width: 4rem;
          text-align: center; }
        .product-details-box .product-add-to-cart .product-quantity .qty .bootstrap-touchspin .input-group-btn {
          float: left;
          border: 0;
          height: 2.5rem;
          background-color: transparent;
          display: block; }
          .product-details-box .product-add-to-cart .product-quantity .qty .bootstrap-touchspin .input-group-btn button {
            background-color: transparent;
            border: 0;
            font-size: 2rem;
            font-weight: 100;
            height: 100%;
            padding: 0;
            font-family: sans-serif; }
  .product-details-box .product-add-to-cart .add {
    display: inline-block;
    width: 100%; }
    .product-details-box .product-add-to-cart .add .add-to-cart {
      margin: 0;
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      height: 2.75rem;
      line-height: 1rem;
      padding-top: 0.875rem; }

.product-discount {
  color: #808A95; }
  .product-discount .regular-price {
    text-decoration: line-through;
    font-weight: normal;
    margin-right: 0.75rem; }

.product-unit-price {
  font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  color: #808A95;
  margin-bottom: 0;
  font-weight: 500; }

.tabs {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  background: white;
  padding: 0.875rem 1rem; }
  .tabs .tab-pane {
    padding-top: 0.875rem; }
  .tabs .nav-tabs {
    border: none;
    border-bottom: #F6F6F6 2px solid; }
    .tabs .nav-tabs .nav-link {
      color: #808A95;
      border: 0 solid transparent; }
      .tabs .nav-tabs .nav-link.active {
        border: none;
        border-bottom: #F4793B 3px solid;
        color: #F4793B; }
      .tabs .nav-tabs .nav-link:hover {
        border: none;
        border-bottom: #F4793B 3px solid; }
    .tabs .nav-tabs .nav-item {
      float: left;
      margin-bottom: -0.125rem; }

.product-cover {
  margin-bottom: 0.875rem;
  position: relative; }
  .product-cover img {
    width: auto;
    max-height: 35rem;
    margin-right: auto;
    margin-left: auto;
    display: block; }
  .product-cover .layer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
    cursor: pointer;
    transition: opacity 0.7s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .product-cover .layer {
        transition: none; } }
    .product-cover .layer:hover {
      opacity: 1; }
    .product-cover .layer .zoom-in {
      font-size: 6.25rem;
      color: #808A95; }

#product-modal .modal-content {
  background: transparent;
  border: none;
  padding: 0; }
  #product-modal .modal-content .modal-body {
    display: flex;
    margin-left: -30%; }
    #product-modal .modal-content .modal-body .product-cover-modal {
      background: white; }
    #product-modal .modal-content .modal-body .image-caption {
      background: white;
      width: 800px;
      padding: 0.625rem 1.25rem;
      border-top: #F6F6F6 1px solid; }
      #product-modal .modal-content .modal-body .image-caption p {
        margin-bottom: 0; }
    #product-modal .modal-content .modal-body .thumbnails {
      position: relative; }
    #product-modal .modal-content .modal-body .mask {
      position: relative;
      overflow: hidden;
      max-height: 49.38rem;
      margin-top: 2.188rem;
      z-index: 1; }
      #product-modal .modal-content .modal-body .mask.nomargin {
        margin-top: 0; }
    #product-modal .modal-content .modal-body .product-images {
      margin-left: 1.125rem; }
      #product-modal .modal-content .modal-body .product-images img {
        width: 9.25rem;
        cursor: pointer;
        background: white; }
        #product-modal .modal-content .modal-body .product-images img:hover {
          border: #F4793B 3px solid; }
    #product-modal .modal-content .modal-body .arrows {
      height: 100%;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      color: white;
      right: 1rem;
      z-index: 0;
      cursor: pointer; }
      #product-modal .modal-content .modal-body .arrows .arrow-up {
        position: absolute;
        top: -2rem;
        opacity: 0.2; }
      #product-modal .modal-content .modal-body .arrows .arrow-down {
        position: absolute;
        bottom: -2rem; }
      #product-modal .modal-content .modal-body .arrows i {
        font-size: 6.25rem;
        display: inline; }

.product-images > li.thumb-container {
  display: inline; }
  .product-images > li.thumb-container > .thumb {
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin-bottom: 0.75rem; }
    .images-container .product-images > li.thumb-container > .thumb {
      margin-right: 0.8125rem; }
    .product-images > li.thumb-container > .thumb.selected, .product-images > li.thumb-container > .thumb:hover {
      border: #F4793B 3px solid; }

#main .images-container .js-qv-mask {
  white-space: nowrap;
  overflow: hidden; }
  #main .images-container .js-qv-mask.scroll {
    width: calc(100% - 60px);
    margin: 0 auto; }

.scroll-box-arrows {
  display: none; }
  .scroll-box-arrows.scroll {
    display: block; }
  .scroll-box-arrows i {
    position: absolute;
    bottom: 1.625rem;
    height: 100px;
    line-height: 100px;
    cursor: pointer; }
  .scroll-box-arrows .left {
    left: 0; }
  .scroll-box-arrows .right {
    right: 0; }

#product-availability .material-icons {
  line-height: inherit; }

#product-availability > span {
  display: inline-block;
  margin: 20px;
  margin-top: 0.625rem;
  margin-bottom: 0;
  font-weight: 700; }

#product-availability .product-available {
  color: #00A13A; }

#product-availability .product-unavailable, #product-availability .product-last-items {
  color: #FFD600; }

#product-details .label {
  font-size: 0.875rem;
  color: #2B3C4E;
  font-weight: bold; }

.product-features {
  margin-top: 0.875rem;
  margin-left: 0.3125rem; }
  .product-features > dl.data-sheet {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch; }
    .product-features > dl.data-sheet dd.value,
    .product-features > dl.data-sheet dt.name {
      flex: 1 0 40%;
      font-weight: normal;
      background: #F6F6F6;
      padding: 0.75rem;
      margin-right: 0.75rem;
      min-height: 2.5rem;
      word-break: normal;
      text-transform: capitalize;
      margin-bottom: .5rem; }
      .product-features > dl.data-sheet dd.value:nth-of-type(even),
      .product-features > dl.data-sheet dt.name:nth-of-type(even) {
        background: #F6F6F6; }

.product-variants > .product-variants-item {
  margin: 0.875rem 0; }
  .product-variants > .product-variants-item select {
    width: 100%;
    background-color: #DDDDDD;
    padding-right: 1.875rem;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none; }
  .product-variants > .product-variants-item ul li {
    margin-right: 0.75rem; }
  .product-variants > .product-variants-item .color {
    margin-left: 0;
    margin-top: 0; }

.product-customization {
  margin: 1.125rem 0; }
  .product-customization .product-customization-item {
    margin: 0.875rem 0; }
  .product-customization .product-message {
    background: #F6F6F6;
    border: none;
    width: 100%;
    height: 3.125rem;
    resize: none;
    padding: 0.625rem; }
    .product-customization .product-message:focus {
      background-color: white;
      outline: 0.1875rem solid #F4793B; }
  .product-customization .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: 2.625rem;
    overflow: hidden;
    position: absolute; }
  .product-customization .customization-message {
    margin-top: 20px; }
  .product-customization .custom-file {
    position: relative;
    background: #F6F6F6;
    width: 100%;
    height: 2.625rem;
    line-height: 2.625rem;
    text-indent: 0.625rem;
    display: block;
    color: #808A95;
    margin-top: 0.875rem; }
    .product-customization .custom-file button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0; }
  .product-customization small {
    color: #808A95; }

.product-pack {
  margin-top: 1.125rem; }
  .product-pack .pack-product-container {
    display: flex;
    justify-content: space-around; }
    .product-pack .pack-product-container .pack-product-name {
      flex: 0 0 50%;
      font-size: 0.875rem;
      color: #808A95; }
    .product-pack .pack-product-container .pack-product-quantity {
      border-left: #F6F6F6 2px solid;
      padding-left: 0.75rem; }
    .product-pack .pack-product-container .pack-product-name,
    .product-pack .pack-product-container .pack-product-price,
    .product-pack .pack-product-container .pack-product-quantity {
      display: flex;
      align-items: center; }

.product-refresh {
  margin-top: 0.875rem; }

.social-sharing {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.5rem; }
  .social-sharing ul {
    margin-bottom: 0; }
  .social-sharing li {
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: inline-block;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    background-position: 0.25rem 0.25rem;
    cursor: pointer;
    margin-left: 0.5rem;
    transition: all 0.2s ease-in; }
    @media (prefers-reduced-motion: reduce) {
      .social-sharing li {
        transition: none; } }
    .social-sharing li a {
      display: block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden; }
      .social-sharing li a:hover {
        color: transparent; }

.products-selection {
  margin-bottom: 0.875rem; }
  .products-selection .title {
    color: #808A95; }

.product-images-container {
  position: relative; }
  .product-images-container:hover .images-controllers {
    opacity: 1;
    z-index: 1; }
  .product-images-container .features-container {
    position: absolute;
    margin-top: 1rem;
    z-index: 1;
    display: flex; }
    .product-images-container .features-container div {
      float: left;
      margin-bottom: 1.5rem;
      text-align: center;
      margin-right: 1rem; }
      .product-images-container .features-container div p {
        margin-left: auto;
        margin-right: auto;
        width: 2.5rem;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 2.5rem;
        border: 2px solid #2B3C4E;
        border-radius: 50%;
        font-size: 1.125rem;
        font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        margin-bottom: 5px; }
        .product-images-container .features-container div p.ext {
          width: auto;
          border: 0; }
      .product-images-container .features-container div label {
        font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        text-transform: uppercase; }
  .product-images-container .images-controllers {
    position: absolute;
    width: 100%;
    margin-top: 4rem;
    opacity: 0;
    transition: 500ms all ease-in-out;
    display: flex;
    justify-content: space-between;
    top: 50%; }
    .product-images-container .images-controllers i {
      font-size: 2.5rem;
      color: #808A95;
      cursor: pointer; }
      .product-images-container .images-controllers i.disabled {
        cursor: default;
        color: #F6F6F6; }
  .product-images-container .images-container {
    overflow: hidden;
    height: 42rem;
    display: block;
    position: relative; }
    @media (max-width: 1279.98px) {
      .product-images-container .images-container {
        height: 37rem; } }
    .product-images-container .images-container .images-container-inner {
      position: absolute;
      top: 7rem; }
      .product-images-container .images-container .images-container-inner .product-cover {
        float: left; }

.maxi-hr {
  position: relative;
  display: inline-block;
  margin-top: 2.5rem;
  margin-bottom: 8rem;
  width: 100%; }
  .maxi-hr:before {
    content: '';
    width: 100vw;
    position: absolute;
    height: 80px;
    background-color: #F6F6F6;
    border: 0;
    left: calc(-50vw + 50%);
    margin-top: 0; }

@media (max-width: 1279.98px) {
  .product-cover img {
    width: 100%; }
  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0; }
    #product-modal .modal-content .modal-body img.product-cover-modal {
      width: 100%; }
    #product-modal .modal-content .modal-body .arrows {
      display: none; }
  #product-modal .modal-content .modal-body .image-caption {
    width: 100%; } }

.blockcart {
  position: relative;
  margin: 0 0.5rem;
  padding: 0.25rem;
  cursor: default;
  text-align: right; }
  @media (min-width: 768px) {
    .blockcart {
      padding: 0.25rem 0.75rem; } }
  .blockcart .cart-products-count {
    position: absolute;
    top: -3px;
    right: 0;
    font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    vertical-align: middle;
    background-color: #F4793B;
    border-radius: 50%;
    color: #FFF;
    width: 20px;
    height: 20px;
    border: 2px solid #FFF;
    text-align: center;
    margin-right: 0.25em; }
  .blockcart i {
    font-size: 1.25rem;
    cursor: inherit; }
  .blockcart a:hover {
    text-decoration: none; }

.blockcart-mini {
  z-index: 16;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  margin: 0 -20px; }
  @media (min-width: 768px) {
    .blockcart-mini {
      position: absolute;
      top: 100%;
      right: 20px;
      width: 29.375rem;
      margin: 32px 0 0; } }
  .blockcart-mini.show {
    visibility: visible;
    opacity: 1;
    max-height: 100%; }
  @media (min-width: 768px) {
    .blockcart-mini.fixed {
      position: fixed;
      top: 14px;
      margin-top: 0; }
      .blockcart-mini.fixed:before {
        display: none; }
      .blockcart-mini.fixed .blockcart-mini-body {
        border: 1px solid #DDDDDD; } }
  .blockcart-mini:before {
    content: '';
    display: none;
    position: absolute;
    top: -0.5rem;
    right: 17px;
    width: 24px;
    height: 24px;
    background-color: #FFF;
    transform: rotate(-45deg);
    box-shadow: rgba(0, 0, 0, 0.05) 4px 10px 20px; }
    @media (min-width: 768px) {
      .blockcart-mini:before {
        display: block; } }
  .blockcart-mini-body {
    position: relative;
    padding: 2rem 0 1rem;
    background-color: #FFF; }
    @media (min-width: 768px) {
      .blockcart-mini-body {
        box-shadow: rgba(0, 0, 0, 0.05) 4px 10px 20px;
        padding: 1rem 0; } }
  .blockcart-mini-title {
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000;
    margin: 0; }
  .blockcart-mini-list {
    border-bottom: 1px solid #DDDDDD;
    margin: 0;
    padding: 0.75rem 0; }
    @media (min-width: 768px) {
      .blockcart-mini-list {
        max-height: 400px;
        overflow-y: auto; } }
  .blockcart-mini-product {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem; }
    .blockcart-mini-product .product-image {
      width: 100%;
      height: auto;
      max-width: 4.75rem; }
    .blockcart-mini-product .product-summary {
      width: 100%;
      margin-left: 1rem; }
    .blockcart-mini-product .product-category {
      margin-bottom: 0.5rem;
      color: #808A95;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.1428; }
    .blockcart-mini-product .product-title {
      margin-bottom: 0.75rem;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.111;
      color: #2B3C4E; }
    .blockcart-mini-product .product-price {
      margin: 0; }
      .blockcart-mini-product .product-price span {
        display: inline-block;
        font-size: 1rem;
        line-height: 1;
        color: #808A95;
        vertical-align: middle; }
      .blockcart-mini-product .product-price .price {
        font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1;
        color: #2B3C4E;
        vertical-align: middle; }
      .blockcart-mini-product .product-price .regular-price {
        font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-weight: 400;
        margin-left: 1rem;
        text-decoration: line-through; }
    .blockcart-mini-product .product-pieces {
      float: right;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #2B3C4E; }
      .blockcart-mini-product .product-pieces:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url(../../../img/pod.svg) no-repeat center;
        background-size: contain;
        vertical-align: middle;
        margin-right: 6px; }
    .blockcart-mini-product .product-actions {
      margin-left: 1rem; }
      .blockcart-mini-product .product-actions .btn, .blockcart-mini-product .product-actions .homepage-row .custom-text a, .homepage-row .custom-text .blockcart-mini-product .product-actions a {
        padding: 0.75rem; }
        .blockcart-mini-product .product-actions .btn i, .blockcart-mini-product .product-actions .homepage-row .custom-text a i, .homepage-row .custom-text .blockcart-mini-product .product-actions a i {
          margin: 0; }
  .blockcart-mini-content {
    margin-top: 1rem;
    padding: 0 1.5rem; }
  .blockcart-mini-totals {
    display: flex;
    flex-wrap: wrap; }
    .blockcart-mini-totals dt {
      width: 50%;
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.333;
      color: #2B3C4E;
      text-transform: uppercase; }
      .blockcart-mini-totals dt.main {
        text-transform: none;
        font-size: 1.125rem;
        line-height: 1.111; }
      .blockcart-mini-totals dt small {
        font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 0.777em;
        font-weight: 500;
        line-height: 2;
        color: #808A95; }
    .blockcart-mini-totals dd {
      text-align: right;
      width: 50%;
      font-size: 1rem;
      line-height: 1.5;
      color: #2B3C4E; }
      .blockcart-mini-totals dd.main {
        font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.111; }

.cart-heading {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .cart-heading {
      margin-top: 3rem;
      margin-bottom: 3rem; } }
  .cart-heading .cart-title {
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.1666;
    color: #2B3C4E; }
    @media (min-width: 768px) {
      .cart-heading .cart-title {
        font-size: 2rem;
        line-height: 1.09; } }

@media (min-width: 1280px) {
  .cart-grid {
    margin-bottom: 3rem; } }

.cart-grid .cart-summary-products {
  margin-bottom: 1rem; }
  @media (min-width: 768px) {
    .cart-grid .cart-summary-products {
      margin-bottom: 2rem; } }

.cart-grid-body {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  @media (min-width: 1280px) {
    .cart-grid-body {
      margin-top: 0;
      margin-bottom: 0; } }
  .cart-grid-body .cart-item {
    padding-bottom: 1rem; }
    @media (min-width: 768px) {
      .cart-grid-body .cart-item {
        padding-bottom: 1.5rem; } }
    .cart-grid-body .cart-item + .cart-item {
      padding-top: 1rem;
      border-top: 2px solid #F6F6F6; }
      @media (min-width: 768px) {
        .cart-grid-body .cart-item + .cart-item {
          padding-top: 1.5rem; } }

.product-line-grid {
  display: flex;
  align-items: center; }
  .product-line-grid-left {
    margin-right: 0.5rem; }
    @media (min-width: 768px) {
      .product-line-grid-left {
        margin-right: 1rem; } }
    .product-line-grid-left img {
      width: 4rem;
      height: auto; }
      @media (min-width: 768px) {
        .product-line-grid-left img {
          width: 7.5rem; } }
  .product-line-grid-body {
    max-width: calc(100% - 5rem - 6%); }
    @media (min-width: 768px) {
      .product-line-grid-body {
        max-width: 100%;
        margin-right: 1rem; } }
    .product-line-grid-body .product-line-info .product-category {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.1428;
      color: #808A95;
      margin-bottom: 0.25em; }
      @media (min-width: 768px) {
        .product-line-grid-body .product-line-info .product-category {
          margin-bottom: 0.5em; } }
    .product-line-grid-body .product-line-info .product-title {
      font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      color: #2B3C4E;
      margin-bottom: 0.25em; }
      @media (min-width: 768px) {
        .product-line-grid-body .product-line-info .product-title {
          font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.1111;
          margin-bottom: 0.875em; } }
    .product-line-grid-body .current-price, .product-line-grid-body .product-discount {
      display: inline-block;
      vertical-align: middle; }
    .product-line-grid-body .current-price {
      font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 500;
      font-size: 1rem;
      line-height: 1;
      color: #2B3C4E; }
      @media (min-width: 768px) {
        .product-line-grid-body .current-price {
          font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.1111; } }
    .product-line-grid-body .product-discount {
      margin-left: 1rem;
      text-decoration: line-through;
      font-size: 1rem;
      line-height: 1;
      color: #808A95; }
    .product-line-grid-body .product-customizations {
      margin-top: 1rem; }
  .product-line-grid-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 auto;
    margin-left: auto;
    width: auto; }
    .product-line-grid-right .product-pieces {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #2B3C4E; }
      .product-line-grid-right .product-pieces:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url(../../../img/pod.svg) no-repeat center;
        background-size: contain;
        vertical-align: middle;
        margin-right: 6px; }
    .product-line-grid-right .product-line-info {
      position: relative;
      padding: 0 0.5rem; }
      @media (min-width: 768px) {
        .product-line-grid-right .product-line-info {
          padding: 0 1rem; } }
      .product-line-grid-right .product-line-info:before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        width: 1px;
        height: 3rem;
        margin-top: -1.5rem;
        vertical-align: middle;
        background-color: #808A95; }
        @media (min-width: 768px) {
          .product-line-grid-right .product-line-info:before {
            height: 4.75rem;
            margin-top: -2.375rem; } }
      .product-line-grid-right .product-line-info:first-child {
        padding-left: 0; }
        .product-line-grid-right .product-line-info:first-child:before {
          display: none; }
      .product-line-grid-right .product-line-info:last-child {
        padding-right: 0; }
    .product-line-grid-right .qty .bootstrap-touchspin {
      position: relative;
      box-shadow: none;
      padding-left: 1.5rem; }
      @media (min-width: 768px) {
        .product-line-grid-right .qty .bootstrap-touchspin {
          display: flex;
          padding-left: 0; } }
    .product-line-grid-right .qty .form-control {
      display: inline-block;
      color: #000;
      text-align: center;
      background: none;
      border: 0;
      width: 100%;
      max-width: 2.8rem;
      float: none; }
      @media (max-width: 767.98px) {
        .product-line-grid-right .qty .form-control {
          padding-right: 0;
          max-width: 3rem; } }
    .product-line-grid-right .qty .input-group-btn {
      position: absolute;
      left: 0; }
      .product-line-grid-right .qty .input-group-btn:first-child {
        bottom: 0; }
      .product-line-grid-right .qty .input-group-btn:last-child {
        top: 0; }
      @media (min-width: 768px) {
        .product-line-grid-right .qty .input-group-btn {
          position: static; } }
    .product-line-grid-right .qty .btn, .product-line-grid-right .qty .homepage-row .custom-text a, .homepage-row .custom-text .product-line-grid-right .qty a {
      color: #FFF;
      background-color: #DDDDDD;
      border: 0;
      font-size: 1.2rem;
      line-height: 1.5;
      padding: 0;
      width: 1.5rem;
      height: 1.5rem; }
      @media (min-width: 768px) {
        .product-line-grid-right .qty .btn, .product-line-grid-right .qty .homepage-row .custom-text a, .homepage-row .custom-text .product-line-grid-right .qty a {
          font-size: 2rem;
          width: 3rem;
          height: 3rem; } }
      .product-line-grid-right .qty .btn:hover, .product-line-grid-right .qty .homepage-row .custom-text a:hover, .homepage-row .custom-text .product-line-grid-right .qty a:hover {
        background-color: #c4c4c4; }
    .product-line-grid-right .remove-from-cart .material-icons {
      margin-left: 0;
      font-size: 18px; }
      @media (min-width: 768px) {
        .product-line-grid-right .remove-from-cart .material-icons {
          font-size: 20px; } }

.cart-grid-right {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  @media (min-width: 1280px) {
    .cart-grid-right {
      margin-top: 0;
      margin-bottom: 0; } }
  .cart-grid-right .cart-summary {
    padding: 1rem 1.5rem;
    background: #F6F6F6; }
  .cart-grid-right .cart-block {
    padding: 1.5rem;
    margin: 0 -1.5rem;
    border-top: 2px solid #FFF; }
    .cart-grid-right .cart-block:first-child {
      border-top: 0; }
  .cart-grid-right .cart-summary-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem; }
    .cart-grid-right .cart-summary-line:first-child {
      padding-top: 0; }
    .cart-grid-right .cart-summary-line .label {
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1.333;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #2B3C4E; }
      .cart-grid-right .cart-summary-line .label small {
        font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.1428;
        color: #808A95; }
    .cart-grid-right .cart-summary-line .value {
      font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      color: #2B3C4E; }
    .cart-grid-right .cart-summary-line.cart-total .label, .cart-grid-right .cart-summary-line.cart-total .value {
      font-size: 1.5rem;
      line-height: 0.92; }
    .cart-grid-right .cart-summary-line.cart-total .label {
      text-transform: none;
      letter-spacing: normal; }
    .cart-grid-right .cart-summary-line.cart-total .value {
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 700; }
  .cart-grid-right .cart-detailed-actions {
    margin-bottom: 1.5rem; }
    .cart-grid-right .cart-detailed-actions .btn i, .cart-grid-right .cart-detailed-actions .homepage-row .custom-text a i, .homepage-row .custom-text .cart-grid-right .cart-detailed-actions a i {
      display: none; }
    #checkout .cart-grid-right .cart-detailed-actions .btn, #checkout .cart-grid-right .cart-detailed-actions .homepage-row .custom-text a, .homepage-row .custom-text #checkout .cart-grid-right .cart-detailed-actions a {
      display: flex;
      text-align: left;
      align-content: center;
      justify-content: space-between; }
      #checkout .cart-grid-right .cart-detailed-actions .btn i, #checkout .cart-grid-right .cart-detailed-actions .homepage-row .custom-text a i, .homepage-row .custom-text #checkout .cart-grid-right .cart-detailed-actions a i {
        display: inline-block;
        font-size: 18px; }

.block-promo .promo-name .label {
  display: flex;
  align-items: center; }
  .block-promo .promo-name .label a {
    display: inline-block;
    margin-right: 6px; }
    .block-promo .promo-name .label a .material-icons {
      font-size: 18px; }

.block-promo .promo-code .alert-danger {
  position: relative;
  margin-top: 1.25rem;
  margin-bottom: 0;
  background: #E30613;
  color: white;
  display: none; }
  .block-promo .promo-code .alert-danger::after {
    bottom: 100%;
    left: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #E30613;
    border-width: 10px;
    margin-left: -10px; }

.block-promo .promo-code form {
  position: relative; }

.block-promo .promo-input {
  display: block;
  padding-right: 7.5625rem;
  font-size: 0.875rem;
  line-height: 1.7857;
  height: calc(1.7857em + 1.3124rem + 2px); }
  .block-promo .promo-input + .btn, .block-promo .homepage-row .custom-text .promo-input + a, .homepage-row .custom-text .block-promo .promo-input + a {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    line-height: 1.333; }

#block-reassurance {
  margin-top: 2rem; }
  #block-reassurance img {
    width: 1.563rem;
    margin-right: 0.625rem; }
  #block-reassurance li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25); }
    #block-reassurance li:last-child {
      border: 0; }
  #block-reassurance li .block-reassurance-item {
    padding: 1rem 1.5rem; }
  #product #block-reassurance {
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
    background: white; }
    #product #block-reassurance span {
      font-weight: 700; }

.quickview .modal-dialog {
  width: calc(100% - 30px);
  max-width: 64rem; }

.quickview .modal-content {
  background: #F6F6F6;
  min-height: 28.13rem; }

.quickview .modal-header {
  border: none;
  padding: 0.75rem; }

.quickview .modal-body {
  min-height: 23.75rem; }

.quickview .modal-footer {
  border-top: 1px solid rgba(246, 246, 246, 0.3); }

.quickview .layer {
  display: none; }

.quickview .product-cover img {
  width: 95%; }

.quickview .images-container {
  display: flex;
  z-index: 1;
  min-height: 21.88rem; }
  .quickview .images-container .product-images > li.thumb-container > .thumb {
    width: 100%;
    max-width: 4.938rem;
    margin-bottom: 0.8125rem;
    background: white; }

.quickview .mask {
  width: 35%;
  max-height: 22.5rem;
  overflow: hidden;
  margin-left: 0.75rem; }

.quickview .arrows {
  position: absolute;
  top: 0;
  bottom: 0;
  max-height: 22.5rem;
  right: 5rem;
  z-index: 0; }
  .quickview .arrows .arrow-up {
    margin-top: -3.125rem;
    cursor: pointer;
    opacity: 0.2; }
  .quickview .arrows .arrow-down {
    position: absolute;
    bottom: -1.875rem;
    cursor: pointer; }

.quickview .social-sharing {
  margin-top: 0;
  margin-left: 1.125rem; }

#stores .page-stores {
  width: 85%;
  margin: 0 auto; }
  #stores .page-stores .store-item {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  #stores .page-stores .store-picture img {
    max-width: 100%; }
  #stores .page-stores .store-item-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0; }
    #stores .page-stores .store-item-container ul {
      margin-bottom: 0;
      font-size: 0.99995rem; }
    #stores .page-stores .store-item-container .divide-left {
      border-left: #F6F6F6 1px solid; }
      #stores .page-stores .store-item-container .divide-left tr {
        height: 1.563rem; }
      #stores .page-stores .store-item-container .divide-left td {
        padding-left: 0.375rem; }
      #stores .page-stores .store-item-container .divide-left th {
        text-align: right; }
    #stores .page-stores .store-item-container .store-description {
      font-size: 0.875rem; }
  #stores .page-stores .store-item-footer {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-around; }
    #stores .page-stores .store-item-footer.divide-top {
      border-top: #F6F6F6 1px solid; }
    #stores .page-stores .store-item-footer div:first-child {
      flex: 0 0 65%; }
    #stores .page-stores .store-item-footer i.material-icons {
      margin-right: 0.75rem;
      color: #808A95;
      font-size: 0.875rem; }
    #stores .page-stores .store-item-footer li {
      margin-bottom: 0.75rem; }

/*** Responsive part ***/
@media (max-width: 767.98px) {
  #stores .page-stores {
    width: 100%; }
    #stores .page-stores .store-item-container {
      padding: 1rem 0; } }

@media (max-width: 575.98px) {
  #stores .page-stores .store-item-container {
    display: block; }
    #stores .page-stores .store-item-container .divide-left {
      border-left: none; }
    #stores .page-stores .store-item-container .store-description a {
      margin-bottom: 0.5rem; }
    #stores .page-stores .store-item-container .store-description address {
      margin-bottom: 0.5rem; }
  #stores .page-stores .store-item-footer {
    display: block; }
    #stores .page-stores .store-item-footer.divide-top {
      border-top: #F6F6F6 1px solid; }
    #stores .page-stores .store-item-footer li {
      margin-bottom: 0.75rem; }
    #stores .page-stores .store-item-footer .card-block {
      padding: 0.75rem 0.75rem 0; } }

.footer-before {
  background-color: #F6F6F6;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: #808A95; }
  .footer-before .row > div {
    margin-bottom: 1.5rem; }
    @media (min-width: 768px) {
      .footer-before .row > div {
        margin-bottom: 0; } }
  .footer-before .row strong, .footer-before .row a {
    color: #2B3C4E;
    font-weight: normal; }
  .footer-before h4 {
    font-size: 0.75rem;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji; }

.block-contact {
  border-left: #F6F6F6 2px solid;
  color: #808A95;
  padding-left: 20px !important; }
  .block-contact .block-contact-title {
    color: #2B3C4E; }

.linklist .blockcms-title a {
  color: #2B3C4E; }

.account-list a {
  color: #808A95; }
  .account-list a:hover {
    color: #F4793B; }

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-weight: 700;
  font-size: 0.875rem; }

.block-social {
  text-align: right; }

.block-social li {
  height: 2.5rem;
  width: 2.5rem;
  background-color: #F6F6F6;
  background-repeat: no-repeat;
  display: inline-block;
  margin: 0.125rem;
  cursor: pointer; }
  .block-social li:hover {
    background-color: #F4793B; }
  .block-social li a {
    display: block;
    height: 100%;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden; }
    .block-social li a:hover {
      color: transparent; }

.facebook {
  background-image: url(../../../img/facebook.svg); }
  .facebook::before {
    content: "";
    background-image: url(../../../img/facebook-blue.svg); }
  .facebook.icon-gray {
    background-image: url(../../../img/facebook-gray.svg); }
    .facebook.icon-gray:hover {
      background-image: url(../../../img/facebook-blue.svg); }

.twitter {
  background-image: url(../../../img/twitter.svg); }
  .twitter::before {
    content: "";
    background-image: url(../../../img/twitter-blue.svg); }
  .twitter.icon-gray {
    background-image: url(../../../img/twitter-gray.svg); }
    .twitter.icon-gray:hover {
      background-image: url(../../../img/twitter-blue.svg); }

.rss {
  background-image: url(../../../img/rss.svg); }

.youtube {
  background-image: url(../../../img/youtube.svg); }

.googleplus {
  background-image: url(../../../img/gplus.svg); }
  .googleplus::before {
    content: "";
    background-image: url(../../../img/gplus-blue.svg); }
  .googleplus.icon-gray {
    background-image: url(../../../img/gplus-gray.svg); }
    .googleplus.icon-gray:hover {
      background-image: url(../../../img/gplus-blue.svg); }

#block_myaccount_infos .myaccount-title a {
  color: #2B3C4E; }

.pinterest {
  background-image: url(../../../img/pinterest.svg); }
  .pinterest::before {
    content: "";
    background-image: url(../../../img/pinterest-blue.svg); }
  .pinterest.icon-gray {
    background-image: url(../../../img/pinterest-gray.svg); }
    .pinterest.icon-gray:hover {
      background-image: url(../../../img/pinterest-blue.svg); }

.vimeo {
  background-image: url(../../../img/vimeo.svg); }

.instagram {
  background-image: url(../../../img/instagram.svg); }

.links .collapse {
  display: inherit; }

@media (max-width: 767.98px) {
  .links .collapse {
    display: none; }
    .links .collapse.in {
      display: block; }
  .links .title {
    padding: 0.625rem;
    border-bottom: 1px solid #F6F6F6;
    cursor: pointer; }
    .links .title .collapse-icons .remove {
      display: none; }
  .links .title[aria-expanded="true"] .collapse-icons .add {
    display: none; }
  .links .title[aria-expanded="true"] .collapse-icons .remove {
    display: block; }
  .links .navbar-toggler {
    display: inline-block;
    padding: 0; } }

@media (max-width: 1279.98px) {
  .block-social {
    text-align: center; } }

.footer-container {
  border-top: 1px solid rgba(128, 138, 149, 0.4);
  padding-top: 2rem; }
  @media (min-width: 768px) {
    .footer-container {
      border-top: 0;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  .footer-container h4 {
    font-size: 0.75rem;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    text-transform: uppercase;
    margin-bottom: 2rem; }
  .footer-container .logo {
    margin-bottom: 1.5rem; }
    @media (min-width: 1280px) {
      .footer-container .logo {
        margin-bottom: 0; } }
    .footer-container .logo a {
      display: inline-block; }
      @media (min-width: 1280px) {
        .footer-container .logo a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center; } }
  @media (max-width: 1279.98px) {
    .footer-container .row {
      position: relative; }
      .footer-container .row > div {
        padding-left: 20px !important;
        padding-right: 20px !important;
        max-width: 50%; }
        .footer-container .row > div:nth-child(1) {
          order: 2; }
        .footer-container .row > div:nth-child(2) {
          order: 3; }
        .footer-container .row > div:nth-child(3) {
          order: 4; }
        .footer-container .row > div:nth-child(4) {
          order: 5; }
        .footer-container .row > div:last-child {
          order: 1;
          margin-bottom: 1.5rem;
          max-width: 100%; } }
  .footer-container hr {
    height: 1px;
    background-color: #808A95;
    opacity: 0.4;
    display: inline-block;
    border: 0;
    width: 100vw;
    margin-left: calc(-50vw + 50%); }

.footer-after {
  margin-top: 0.5rem;
  padding-bottom: .5rem; }
  .footer-after .container {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 2.25;
    color: #808A95; }
    @media (min-width: 768px) {
      .footer-after .container > div {
        float: left; } }
    .footer-after .container p {
      margin: 0; }
    .footer-after .container .credits {
      margin-top: 1rem; }
      @media (min-width: 768px) {
        .footer-after .container .credits {
          margin-top: 0;
          float: right; } }
      .footer-after .container .credits a {
        color: #F4793B; }

.contact-rich {
  color: #808A95;
  margin-bottom: 2rem;
  word-wrap: break-word; }
  .contact-rich h4 {
    text-transform: uppercase;
    color: #2B3C4E;
    margin-bottom: 2rem; }
  .contact-rich .block {
    height: auto;
    overflow: hidden; }
    .contact-rich .block .icon {
      float: left;
      width: 3.5rem; }
      .contact-rich .block .icon i {
        font-size: 2rem; }
    .contact-rich .block .data {
      color: #2B3C4E;
      font-size: 0.74996rem;
      width: auto;
      overflow: hidden; }
      .contact-rich .block .data.email {
        padding-top: 0.375rem; }

.contact-form {
  background: white;
  padding: 1rem;
  color: #808A95;
  width: 100%; }
  .contact-form h3 {
    text-transform: uppercase;
    color: #2B3C4E; }

#products #main .page-header,
#pagenotfound #main .page-header {
  margin: 2rem 0 3rem; }

#products #main .page-content,
#pagenotfound #main .page-content {
  margin-bottom: 10rem; }

#products .page-not-found,
#pagenotfound .page-not-found {
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: white;
  padding: 1rem;
  font-size: 0.74996rem;
  color: #808A95;
  max-width: 570px;
  margin: 0 auto; }
  #products .page-not-found h4,
  #pagenotfound .page-not-found h4 {
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0.5rem 0 1rem; }
  #products .page-not-found .search-widget,
  #pagenotfound .page-not-found .search-widget {
    float: none; }
    #products .page-not-found .search-widget input,
    #pagenotfound .page-not-found .search-widget input {
      width: 100%; }

.customization-modal .modal-content {
  border-radius: 0;
  border: 1px solid #F6F6F6; }
  .customization-modal .modal-content .modal-body {
    padding-top: 0; }
    .customization-modal .modal-content .modal-body .product-customization-line {
      padding-bottom: 0.9375rem;
      padding-top: 0.9375rem;
      border-bottom: 1px solid #F6F6F6; }
      .customization-modal .modal-content .modal-body .product-customization-line .label {
        font-weight: bold;
        text-align: right; }
      .customization-modal .modal-content .modal-body .product-customization-line:last-child {
        padding-bottom: 0;
        border-bottom: 0; }

.sitemap-title {
  text-transform: capitalize; }

.sitemap {
  margin-top: 0.9375rem; }
  .sitemap h2 {
    color: #2B3C4E;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
    border-bottom: 1px solid #DDDDDD;
    margin-left: -15px;
    width: 100%;
    height: 35px; }
  .sitemap ul {
    margin-left: -15px;
    margin-top: 20px; }
    .sitemap ul.nested {
      margin-left: 20px; }
    .sitemap ul li {
      font-size: 0.9rem;
      margin-bottom: 1rem; }

@media (max-width: 575.98px) {
  .sitemap {
    margin-top: 0; } }

.user-info {
  margin: 0 0.125rem;
  text-align: right; }
  @media (min-width: 1280px) {
    .user-info {
      margin-bottom: 0; } }
  .user-info a {
    text-transform: uppercase;
    font-weight: 700;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 0.75rem;
    line-height: 1.1428;
    letter-spacing: 1px;
    padding: 0.25rem; }
    @media (min-width: 768px) {
      .user-info a {
        padding: 0.25rem 0.75rem; } }
    .user-info a:hover {
      text-decoration: none; }

#tophome_products {
  width: 100vw;
  margin-left: calc(50% - 50vw);
  background-color: #F6F6F6; }
  #tophome_products:after {
    content: '';
    clear: both;
    display: block; }
  #tophome_products .products {
    position: relative;
    padding-bottom: 5rem; }
    #tophome_products .products .carousel-inner {
      margin: 0 -20px;
      width: auto; }
      @media (min-width: 1280px) {
        #tophome_products .products .carousel-inner {
          display: flex;
          flex-wrap: wrap; } }
    #tophome_products .products .carousel-item {
      padding: 0 20px; }
      @media (min-width: 1280px) {
        #tophome_products .products .carousel-item {
          display: block;
          width: 100%;
          max-width: 33.33333%;
          flex: 0 0 33.33333%;
          margin: 0; } }
    #tophome_products .products .miniature {
      background-color: #FFF;
      padding: 1rem; }
      #tophome_products .products .miniature .product-thumbnail {
        display: block;
        text-align: center;
        margin-bottom: 0.5rem; }
        #tophome_products .products .miniature .product-thumbnail img {
          width: 100%;
          max-height: 13rem;
          object-fit: contain; }
      #tophome_products .products .miniature .product-purchase {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        @media (min-width: 1280px) {
          #tophome_products .products .miniature .product-purchase {
            flex-wrap: wrap; } }
        @media (min-width: 1440px) {
          #tophome_products .products .miniature .product-purchase {
            flex-wrap: nowrap; } }
        #tophome_products .products .miniature .product-purchase .product-category {
          text-transform: uppercase;
          font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-weight: 700;
          font-size: 0.75rem;
          line-height: 1.333;
          color: #808A95;
          letter-spacing: 1px;
          margin-bottom: 0.5rem; }
        #tophome_products .products .miniature .product-purchase .product-title {
          color: #2B3C4E;
          font-size: 1.125rem;
          text-decoration: none;
          font-weight: 700;
          line-height: 1.111;
          margin: 0; }
          @media (min-width: 1280px) {
            #tophome_products .products .miniature .product-purchase .product-title {
              font-size: 1.5rem;
              line-height: 1.083; } }
      #tophome_products .products .miniature .product-btn {
        margin-left: 1rem;
        flex: 0 0 auto; }
        @media (min-width: 1280px) {
          #tophome_products .products .miniature .product-btn {
            margin-left: 0;
            width: 100%;
            margin-top: 1rem; } }
        @media (min-width: 1440px) {
          #tophome_products .products .miniature .product-btn {
            margin-left: 1rem;
            width: auto;
            margin-top: 0; } }
        @media (min-width: 1280px) {
          #tophome_products .products .miniature .product-btn .btn, #tophome_products .products .miniature .product-btn .homepage-row .custom-text a, .homepage-row .custom-text #tophome_products .products .miniature .product-btn a {
            display: block; } }
        @media (min-width: 1440px) {
          #tophome_products .products .miniature .product-btn .btn, #tophome_products .products .miniature .product-btn .homepage-row .custom-text a, .homepage-row .custom-text #tophome_products .products .miniature .product-btn a {
            display: inline-block; } }
    #tophome_products .products .carousel-indicators {
      top: 100%;
      bottom: auto;
      margin-top: 1rem; }
      @media (min-width: 1280px) {
        #tophome_products .products .carousel-indicators {
          display: none; } }
      #tophome_products .products .carousel-indicators li {
        width: 4px;
        height: 4px;
        border: 2px solid #808A95;
        background: none;
        border-radius: 50%;
        margin-right: 8px;
        margin-left: 8px; }
        #tophome_products .products .carousel-indicators li.active {
          background-color: #808A95; }

.homepage-row {
  margin: 0 -20px 2.5rem; }
  @media (min-width: 1280px) {
    .homepage-row {
      margin-bottom: 0; } }
  @media (min-width: 1440px) {
    .homepage-row {
      margin-bottom: 2.5rem;
      display: flex;
      flex-wrap: wrap; } }
  .homepage-row .homepage-banner {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-bottom: 2.5rem; }
    @media (min-width: 1440px) {
      .homepage-row .homepage-banner {
        width: 50%;
        flex: 0 0 50%;
        margin-bottom: 0;
        margin-left: 0;
        padding: 0 20px; } }
  .homepage-row .custom-text {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 22rem;
    padding: 1rem;
    text-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25); }
    .homepage-row .custom-text:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background-color: rgba(0, 0, 0, 0.2);
      z-index: -1; }
    .homepage-row .custom-text img {
      position: absolute;
      z-index: -2;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      object-fit: cover; }
    .homepage-row .custom-text p {
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      color: #FFF;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 900;
      max-width: 70%;
      margin-left: auto;
      margin-right: auto;
      display: block;
      text-align: center;
      letter-spacing: 1px; }
    .homepage-row .custom-text strong {
      font-size: 2rem;
      text-align: center; }
  .homepage-row .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 -20px; }
  .homepage-row .product-miniature {
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .homepage-row .product-miniature {
        width: 50%;
        max-width: 19.625rem; } }
    @media (min-width: 1280px) {
      .homepage-row .product-miniature {
        width: 29%; } }
    @media (min-width: 1440px) {
      .homepage-row .product-miniature {
        width: 50%; } }
    @media (min-width: 1280px) and (max-width: 1439.98px) {
      .homepage-row .product-miniature:nth-child(n+4) {
        display: none; } }
    @media (min-width: 1280px) {
      .homepage-row .product-miniature:nth-child(n+3) {
        margin-bottom: 0; } }
  @media (min-width: 1280px) {
    .homepage-row:last-child {
      flex-direction: row-reverse; } }

.search_filter_controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem; }

.facet-dropdown {
  color: #808A95;
  padding-left: 0;
  padding-right: 0;
  background: #F6F6F6;
  border: 3px solid transparent;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px 1px #F6F6F6; }
  .facet-dropdown.open {
    border: 0; }
    .facet-dropdown.open > .select-title {
      border: 3px solid #F4793B;
      background: #F6F6F6; }
  .facet-dropdown .select-title {
    display: inline-flex;
    align-items: center;
    margin-left: 0;
    width: 100%;
    color: #2B3C4E;
    background: #F6F6F6;
    padding: 0.75rem 0.75rem 0.75rem 0.875rem;
    cursor: pointer; }
    .facet-dropdown .select-title > i {
      margin-left: auto; }
  .facet-dropdown .select-list {
    display: block;
    color: #2B3C4E;
    background: #F6F6F6;
    padding: 0.75rem 0.875rem; }
    .facet-dropdown .select-list:hover {
      background: #F4793B;
      color: white;
      text-decoration: none; }
  .facet-dropdown .dropdown-menu {
    padding: 0;
    margin-top: 3px;
    left: auto;
    width: 100%;
    background: #F6F6F6;
    border: none;
    box-shadow: 1px 1px 1px 1px #F6F6F6; }

.search_filters_wrapper .clear-all-wrapper .btn, .search_filters_wrapper .clear-all-wrapper .homepage-row .custom-text a, .homepage-row .custom-text .search_filters_wrapper .clear-all-wrapper a {
  font-size: 0.625rem;
  line-height: 2.1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #808A95; }

.search_filters_head {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.search_filters {
  margin-bottom: 1rem; }
  .search_filters .facet {
    border-bottom: 1px solid #DDDDDD;
    margin: 0 -20px 1rem;
    padding: 0 20px; }
    @media (min-width: 1280px) {
      .search_filters .facet {
        border: 0;
        margin: 0;
        padding: 0; } }
    .search_filters .facet .facet-head {
      margin-bottom: 1rem; }
      .search_filters .facet .facet-head:hover .navbar-toggler {
        color: #F4793B; }
    .search_filters .facet .navbar-toggler, .search_filters .facet .facet-title {
      cursor: pointer; }
    .search_filters .facet .navbar-toggler {
      display: inline-block;
      padding: 0; }
      .search_filters .facet .navbar-toggler .material-icons {
        font-size: 18px; }
    .search_filters .facet .facet-title {
      display: inline-block;
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: bold;
      font-size: 0.75rem;
      line-height: 1.333;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #2B3C4E;
      margin: 0; }
    .search_filters .facet .facet-label a {
      display: inline-block;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.928;
      color: #2B3C4E; }
    .search_filters .facet .custom-checkbox input[type="checkbox"] + span {
      position: relative;
      border: 2px solid #DDDDDD;
      margin-left: 0; }
    .search_filters .facet .custom-checkbox input[type="checkbox"]:checked + span:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      bottom: 2px;
      right: 2px;
      border-radius: 50%;
      background-color: #00A13A; }

.pagination {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem; }
  .pagination .pagination-summary {
    font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #808A95; }
  .pagination .pagination-list {
    margin-left: auto; }
  .pagination .page-list {
    margin: 0; }
    .pagination .page-list li {
      display: inline-block;
      vertical-align: middle; }
    .pagination .page-list .material-icons {
      font-size: 18px; }
    .pagination .page-list a {
      font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.25;
      color: #808A95;
      margin: 0 0.25rem;
      padding: 0 0.25rem;
      text-transform: uppercase;
      letter-spacing: 1px; }
      .pagination .page-list a.previous, .pagination .page-list a.next {
        font-family: Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1; }
      .pagination .page-list a.previous {
        color: #808A95; }
      .pagination .page-list a.next {
        color: #808A95; }
    .pagination .page-list .current a {
      color: #2B3C4E; }

.brands-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px; }
  .brands-list > li {
    padding: 0 20px; }
  .brands-list .brand {
    margin-bottom: 40px;
    flex: 0 0 50%;
    text-align: center; }
    @media (min-width: 768px) {
      .brands-list .brand {
        flex: 0 0 33.3333%; } }
    @media (min-width: 1280px) {
      .brands-list .brand {
        flex: 0 0 25%; } }
    .brands-list .brand .brand-img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10rem; }
      .brands-list .brand .brand-img a {
        display: block;
        height: inherit; }
      .brands-list .brand .brand-img img {
        max-height: 100%;
        width: auto; }
    .brands-list .brand .brand-products a {
      margin: 0 0.25rem;
      color: #F4793B; }

.main-header {
  border-bottom: 1px solid #DDDDDD; }
  .main-header .header-top {
    padding: 1rem 0; }
    .main-header .header-top h1 {
      margin: 0; }
    .main-header .header-top .logo {
      display: inline-block; }
    @media (min-width: 768px) {
      .main-header .header-top .header-display-nav2 {
        display: flex;
        align-items: center;
        justify-content: flex-end; } }
    @media (min-width: 1280px) {
      .main-header .header-top .header-display-nav2 {
        margin-top: auto; } }
  .main-header .open-menu-icon {
    border: 0;
    appearance: none;
    background: none; }

.header-nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0s, visible 0s;
  transition-delay: .5s; }
  .header-nav:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    visibility: hidden;
    opacity: 0;
    transition: opacity .5s, visibility 0s .5s;
    cursor: pointer; }
  .header-nav.open {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s; }
    .header-nav.open:before {
      visibility: visible;
      opacity: .3;
      transition: opacity .5s; }
  @media (min-width: 768px) {
    .header-nav {
      display: none !important; } }

.header-nav-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 85%;
  margin-left: 15%;
  padding: 20px;
  transform: translateX(100%);
  transition: transform .1s ease-out;
  overflow-y: auto;
  background: #FFF; }
  .open .header-nav-wrapper {
    transform: translateX(0);
    transition: transform .15s ease-in; }

.header-nav-logo {
  display: block;
  text-align: center;
  margin-bottom: 1.5rem; }
  .header-nav-logo a {
    display: inline-block; }
  .header-nav-logo .logo {
    display: inline-block;
    max-width: 5rem;
    height: auto; }

.menu-hook-nav {
  margin-top: 1rem;
  border-top: 1px solid #DDDDDD;
  padding-top: 1.5rem; }
  .menu-hook-nav h1, .menu-hook-nav h2, .menu-hook-nav h3, .menu-hook-nav h4, .menu-hook-nav h5, .menu-hook-nav h6 {
    font-size: 0.875rem;
    text-transform: uppercase; }
  .menu-hook-nav a {
    display: block;
    font-family: Archivo, Poppins, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.333;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #2B3C4E;
    padding: 0.25rem 0; }

.rg-header {
  display: block; }
  @media (min-width: 768px) {
    .rg-header {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem; } }
  @media (min-width: 1280px) {
    .rg-header {
      flex-wrap: wrap;
      margin-top: 0; } }
  @media (min-width: 1280px) {
    .rg-header > div {
      width: 100%; } }

.free-shipping-over {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F4793B;
  color: #FFF;
  padding: 0.5rem 1rem; }
  @media (min-width: 768px) {
    .free-shipping-over {
      background: none;
      color: #2B3C4E;
      padding: 0; } }
  .free-shipping-over i.local_shipping {
    display: inline-block;
    font-size: 1.5rem;
    margin-right: 1rem; }
    @media (min-width: 1280px) {
      .free-shipping-over i.local_shipping {
        font-size: 2.5rem; } }
  .free-shipping-over p {
    margin: 0;
    font-size: 0.75rem; }
    @media (min-width: 1280px) {
      .free-shipping-over p {
        font-size: 0.875rem; } }
  @media (min-width: 768px) {
    .free-shipping-over strong {
      color: #F4793B; } }

#wrapper .banner {
  margin-bottom: 1.5rem;
  display: block; }
  #wrapper .banner img {
    box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15); }

#wrapper .breadcrumb {
  background: transparent;
  padding: 0;
  margin-top: 2rem; }
  #wrapper .breadcrumb[data-depth="1"] {
    display: none; }
  #wrapper .breadcrumb ol {
    padding-left: 0;
    margin-bottom: 0; }
  #wrapper .breadcrumb li {
    display: inline; }
    #wrapper .breadcrumb li:last-child {
      margin: 0; }
      #wrapper .breadcrumb li:last-child a {
        color: #2B3C4E; }
    #wrapper .breadcrumb li a {
      color: #808A95;
      font-weight: 500; }
  #wrapper .breadcrumb i {
    color: #808A95;
    font-size: 18px; }

#main .page-header {
  margin-bottom: 1.563rem; }

#main .page-content {
  margin-bottom: 1.563rem; }
  #main .page-content h6 {
    margin-bottom: 1.125rem; }
  #main .page-content #notifications {
    margin-left: -15px;
    margin-right: -15px; }

#main .page-footer {
  margin-bottom: 1.563rem; }

.left-column,
.right-column,
.content-wrapper {
  width: 100%;
  flex: 0 0 100%;
  padding: 0 20px; }

@media (min-width: 1280px) {
  .left-column, .right-column {
    width: 25%;
    flex: 0 0 25%; } }

@media (min-width: 1280px) {
  .content-wrapper {
    width: 50%;
    flex: 0 0 50%; }
    .content-wrapper:first-child, .content-wrapper:last-child {
      width: 75%;
      flex: 0 0 75%; }
    .content-wrapper:first-child:last-child {
      width: 100%;
      flex: 0 0 100%; } }

#footer {
  padding-top: 2.5rem; }

@media (max-width: 767.98px) {
  #footer {
    padding-top: 0.5rem; } }

.footer-container li {
  margin-bottom: 0.3125rem; }

.footer-container li a {
  color: #808A95;
  cursor: pointer;
  font-size: 0.74996rem; }
  .footer-container li a:hover {
    color: #F4793B; }

@media (max-width: 767.98px) {
  .footer-container {
    box-shadow: none;
    margin-top: 0; }
    .footer-container .wrapper {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .footer-container .links .h3 {
      line-height: 1.5;
      font-size: 1rem; }
    .footer-container .links ul {
      background-color: #F6F6F6;
      margin-bottom: 0; }
      .footer-container .links ul > li {
        padding: 0.625rem;
        border-bottom: 1px solid white;
        font-weight: bold; }
        .footer-container .links ul > li a {
          color: #2B3C4E; } }

.block_newsletter {
  font-size: 0.74996rem;
  margin-bottom: 0.625rem; }
  .block_newsletter p {
    padding-top: 0.75rem; }
  .block_newsletter form .input-wrapper {
    overflow: hidden; }
  .block_newsletter form input[type=email] {
    padding: 11px;
    width: 100%; }
    .block_newsletter form input[type=email]:focus {
      border: 3px #F4793B solid;
      padding: 8px 8px 9px;
      outline: 0; }
  .block_newsletter form input {
    height: 42px;
    box-shadow: none; }

@media (max-width: 767.98px) {
  .block_newsletter {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid #F6F6F6; } }

html {
  overflow-x: hidden; }

.show-mobile {
  display: block !important; }

.hide-md-mobile {
  display: none !important; }
  @media (min-width: 768px) {
    .hide-md-mobile {
      display: block !important; } }

.hide-mobile {
  display: none !important; }
  @media (min-width: 1280px) {
    .hide-mobile {
      display: block !important; } }

.popover {
  font-family: inherit; }

#notifications ul {
  margin-bottom: 0; }

.img-responsive {
  max-width: 100%;
  height: auto; }

.product-flag.pack {
  display: none !important; }
